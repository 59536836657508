import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Upload } from "antd";
import { getData, submitContent } from "./service";
import { UploadOutlined } from "@ant-design/icons";

function Features() {
  const [form] = Form.useForm();
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [content, setContent] = useState({});

  const onFinish = async (values) => {
    const payload = {
      value: {
        ...values,
        image: uploadedUrl,
        _id: content.value.id,
      },
    };
    try {
      const response = await submitContent(content._id, payload);
      if (response?.status == 200) {
        message.success("Content Published");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getContent = async () => {
    const response = await getData();
    const {
      data: { data },
    } = response;
    setContent(data.data[0]);
    form.setFieldsValue(data.data[0].value);
    setUploadedUrl(data.data[0].value.image);
  };

  useEffect(() => {
    getContent();
  }, []);

  const props = {
    name: "file",
    action: `${process.env.REACT_APP_API_BASE_URL}/file/multi/landing_testimonial`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setUploadedUrl(info.file.response.data.file);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <div className="bg-white p-6 rounded-2xl">
        <h3 className="text-ufoGreen text-xl font-medium border-b pb-2.5 flex justify-start">
          Features
        </h3>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="mt-5"
        >
          <Form.Item
            label={
              <div className={"text-black text-md ml-2 font-medium"}>
                Feature Section Tagline{" "}
              </div>
            }
            name="tagLine"
            rules={[
              { required: true, message: "Please input the About Tagline!" },
            ]}
          >
            <Input
              placeholder="Hero Tagline"
              className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-2 outline-0"
            />
          </Form.Item>

          <Form.Item
            label={
              <div className={"text-black text-md ml-2 font-medium"}>
                Feature Section Title{" "}
              </div>
            }
            name="title"
            rules={[
              { required: true, message: "Please input the About Title!" },
            ]}
          >
            <Input
              placeholder="Hero Title"
              className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-2 outline-0"
            />
          </Form.Item>

          <Form.Item
            label={
              <div className={"text-black text-md ml-2 font-medium"}>
                Feature Section Description
              </div>
            }
            name="description"
            rules={[
              { required: true, message: "Please input the Hero Description!" },
            ]}
          >
            <Input.TextArea placeholder="Hero Description" rows={10} />
          </Form.Item>

          <Form.Item label="Upload Image" name="image">
            {uploadedUrl && (
              <img src={uploadedUrl} class="rounded-xl w-[300px] h-[300px]" />
            )}
            <Upload {...props} multiple={false}>
              <Button
                icon={<UploadOutlined />}
                className="flex p-3 px-8 items-center justify-start bg-redOrange font-medium text-md h-auto text-white "
              >
                Click to Upload
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <div className="flex justify-end gap-3">
              <input
                type="submit"
                defaultValue="Save"
                className="cursor-pointer leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default Features;
