import React, { useState } from "react";
import { Tabs } from "antd";
import { useEffect } from "react";
import { getAllOutletApi } from "../../../services/outlet";
import { getAllCustomerApi } from "../../../services/customer";
import CustomerBalanceReport from "../../../component/reporting/customer/customer-balance-report";
import CustomerLoyalityReport from "../../../component/reporting/customer/loyality-report";
import { getUserPermissions } from "../../../redux/feature/authSlice";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

function CustomerReporting() {
  const [outlet, setOutlet] = useState([]);
  const [customer, setCustomer] = useState([]);
  const allowedPermissionFromStore = useSelector(getUserPermissions) || {};
  const [tabWithPermission, setTabWithPermission] = useState([]);

  const getOutlet = async () => {
    try {
      const { data: response } = await getAllOutletApi();
      const { data: record } = response;
      setOutlet(record?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getOutlet();
  }, []);

  const getCustomer = async () => {
    try {
      const { data: response } = await getAllCustomerApi();
      const { data: record } = response;
      setCustomer(record?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCustomer();
  }, []);

  const tabData = [
    {
      key: "1",
      label: "Customer Balance Report",
      children: <CustomerBalanceReport outlet={outlet} />,
      permission: "GetCustomerBalances",
    },
    {
      key: "2",
      label: "Loyalty Points",
      children: <CustomerLoyalityReport customer={customer} />,
      permission: "GetCustomerLoyalityReport",
    },
  ];

  useEffect(() => {
    if (allowedPermissionFromStore.masterUser == true) {
      setTabWithPermission(tabData);
    } else {
      const userPermissions = new Set(
        allowedPermissionFromStore?.permissions.map((p) => p.key),
      );
      const filteredTabs = tabData.filter((tab) =>
        userPermissions.has(tab.permission),
      );

      setTabWithPermission(filteredTabs);
    }
  }, []);

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:px-3 xxl:px-6 sm:py-4 xxl:py-8 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8">
          <h2 className="sm:text-xl xxl:text-2xl font-medium">
            Customer Reporting
          </h2>
        </div>

        <div className="overflow-auto scrollbar">
          <Tabs
            defaultActiveKey="1"
            className="filtersTabMain"
            items={tabWithPermission}
          />
        </div>
      </div>
    </main>
  );
}

export default CustomerReporting;
