import { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";
import RegisterTable from "../../table/reportingTable";
import CustomDateRange from "../../../shared/customDateRange";
import { dropDownPayload } from "../../../../utils/helper";
import moment from "moment";

function CustomerBalanceReport({ outlet }) {
  const [searchText, setSearchText] = useState("");
  const [dates, setDates] = useState(null);
  const [filterValue, setFilterValue] = useState({
    showOnlyWithoutSales: true,
    startDate: "",
    endDate: "",
    outletIds: "",
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (balance) => {
        return Number(balance).toFixed(2);
      },
    },
  ];
  const handleChange = (value) => {
    setDates(value);
    if (value) {
      const startDate = value[0].toString();
      const endDate = value[1].toString();
      setFilterValue((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
      }));
    }
  };

  const onChangeOutlet = (options) => {
    setFilterValue((prevState) => ({
      ...prevState,
      outletIds: options.map((outlet) => outlet), // Extract IDs from selected outlets
    }));
  };

  useEffect(() => {
    setFilterValue((prevState) => ({
      ...prevState,
    }));
  }, []);
  return (
    <>
      <div className="flex flex-col justify-between">
        <Row className="mb-4">
          <Col span={8}>
            <CustomDateRange
              value={dates}
              handleChange={handleChange}
              format="YYYY-MM-DD"
            />
          </Col>
          <Col span={1}></Col>
          <Col span={8}>
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              options={dropDownPayload(outlet)}
              placeholder={"Select Outlet"}
              onChange={onChangeOutlet}
            />
          </Col>
        </Row>
        <RegisterTable
          columns={columns}
          endPoint={`report/customer-balances`}
          search={searchText}
          filters={filterValue}
          showActions={false}
        />
      </div>
    </>
  );
}
export default CustomerBalanceReport;
