import React, { useEffect, useState } from "react";
import { Form } from "antd";
import CustomModal from "../../../component/shared/modal";
import SearchInput from "../../../component/shared/searchInput";
import CustomTable from "../../../component/shared/customTable";
import {
  changeTenantStatus,
  createTenant,
  deleteTenantApi,
  updateTenant,
} from "../../../services/user/tenant";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import AddTenant from "../../../component/user/tenant/addTenant";
import moment from "moment";
import ActionAccessWrapper from "../../../component/shared/actionAccessWrapper";

function Tenant() {
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const [form] = Form.useForm();

  const onClickStatus = async (row) => {
    try {
      const payload = {
        ...row,
        isActive: !row.isActive,
        parentId: null,
      };
      const { data } = await changeTenantStatus(row._id, payload);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Status updated successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };
  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteTenantApi(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onFinish = async (values) => {
    let response;
    try {
      const payload = {
        ...values,
        isActive: true,
      };
      if (id) {
        response = await updateTenant(payload, id);
      } else {
        response = await createTenant(payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Tenant successfully saved");
        setId(null);
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  const onClickEdit = async (row) => {
    setId(row._id);
    form.setFieldsValue({
      ...row,
      subscribedAt: moment(row.subscribedAt).format("YYYY-MM-DD"),
      expiredAt: moment(row.expiredAt).format("YYYY-MM-DD"),
    });
    setIsModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => (
        <a className="table-edit-link" onClick={() => onClickEdit(row)}>
          {text}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact",
      dataIndex: "contactNo",
      key: "contactNo",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Subscribe At",
      dataIndex: "subscribedAt",
      key: "subscribedAt",
      render: (_, row) => <p>{new Date(row.subscribedAt).toDateString()}</p>,
    },
    {
      title: "Expired At",
      dataIndex: "expiredAt",
      key: "expiredAt",
      render: (_, row) => <p>{new Date(row.expiredAt).toDateString()}</p>,
    },
    {
      title: "Package",
      dataIndex: "package",
      key: "package",
      render: (_, row) => (
        <p>
          {row.package.name} | {row.package.packageMode.value}
        </p>
      ),
    },
  ];

  return (
    <main className="mt-6 flex-1 flex flex-col overflow-hidden">
      <div className="p-6 bg-white rounded-3xl h-full flex flex-col">
        <div className="flex items-center justify-between mb-8 text-left">
          <h2 className="text-2xl font-medium flex-1">Tenant</h2>
          <ActionAccessWrapper permissionKeys={"CreateTenant"}>
            <a
              onClick={() => openModal()}
              id="addProductTypeBtn"
              className="block capitalize py-4 px-8 rounded-full leading-none text-bold bg-redOrange text-white"
            >
              Add Tenant
            </a>
          </ActionAccessWrapper>

          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={"Add Tenant"}
          >
            <AddTenant form={form} onFinish={onFinish} id={id} />
          </CustomModal>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex flex-col justify-between overflow-auto scrollbar tenantDeatils-table">
          <CustomTable
            refreshTable={refreshTable}
            columns={columns}
            endPoint={"tenant/list"}
            search={searchText}
            // handleStatus={onClickStatus}
            handleDelete={onClickDelete}
            handleEdit={onClickEdit}
            showActions={true}
            permissions={{
              edit: "UpdateTenantById",
              status: "UpdateTenantById",
              delete: "DeleteTenantById",
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default Tenant;
