import React, { useState } from "react";
import CustomModal from "../../../component/shared/modal";
import SearchInput from "../../../component/shared/searchInput";
import CustomTable from "../../../component/shared/customTable";
import AddBrands from "../../../component/products/addBrands";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import { Form } from "antd";
import {
  createSlug,
  deleteslug,
  updateSlug,
} from "../../../services/product/brand";
import ActionAccessWrapper from "../../../component/shared/actionAccessWrapper";

function Unit({ type, heading }) {
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [id, setId] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => (
        <a className="table-edit-link" onClick={() => onClickHandle(row)}>
          {text}
        </a>
      ),
    },
    {
      title: "Products",
      dataIndex: "count",
      key: "count",
      align: "center",
      render: (_, row) => {
        return row?.count || "0";
      },
    },
  ];
  const onFinish = async (values) => {
    let response;

    try {
      const payload = {
        ...values,
        isActive: true,
      };
      if (id) {
        response = await updateSlug(type, payload, id);
      } else {
        response = await createSlug(type, payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Unit successfully saved");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteslug(type, row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onClickHandle = (row) => {
    setId(row._id);
    form.setFieldsValue(row);
    setIsModalOpen(true);
  };

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:p-3 xxl:p-6 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8 text-left">
          <h2 className="sm:text-xl xxl:text-2xl font-medium flex-1">
            {heading}
          </h2>
          <ActionAccessWrapper permissionKeys={"CreateUnit"}>
            <a
              onClick={() => openModal()}
              id="addProductTypeBtn"
              className="block capitalize sm:py-3 xxl:py-4 sm:px-4 xxl:px-6 rounded-full leading-none text-bold bg-redOrange text-white"
            >
              Add {heading}
            </a>
          </ActionAccessWrapper>

          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={`Add ${heading}`}
          >
            <AddBrands form={form} onFinish={onFinish} />
          </CustomModal>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex-1 overflow-y-scroll scrollbar unitListing-table">
          <CustomTable
            columns={columns}
            endPoint={`product-slug/${type}`}
            refreshTable={refreshTable}
            search={searchText}
            handleEdit={onClickHandle}
            handleDelete={onClickDelete}
            showActions={true}
            permissions={{
              edit: "UpdateUnitById",
              status: "UpdateUnitById",
              delete: "DeleteUnitById",
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default Unit;
