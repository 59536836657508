import React, { useEffect, useState } from "react";
import BasicCard from "../../../component/shared/basicCard/basicCard";
import { Checkbox, Col, Form, Input, Row, Switch } from "antd";
import Information from "../../../component/products/productSection/information";
import Pricing from "../../../component/products/productSection/pricing";
import Category from "../../../component/products/productSection/category";
import UploadImage from "../../../component/products/productSection/uploadImage";
import ComponentProduct from "../../../component/products/productSection/componentProduct";
import {
  getSlugListOfValuesByKey,
  getAllListOfValueByKey,
} from "../../../services/globalService";
import { getAllCustomerApi } from "../../../services/customer";
import { getAllProductTypeApi } from "../../../services/product/productType";
import {
  createProduct,
  getProductById,
  updateProduct,
} from "../../../services/product";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import { useLocation, useNavigate } from "react-router-dom";
import CustomModal from "../../../component/shared/modal";
import AddVariants from "../../../component/products/productSection/addVariants";
import { getAllTaxApi } from "../../../services/product/tax";
import { getAllOutletApi } from "../../../services/outlet/index";
const productAction = [
  {
    id: 1,
    key: "NO_VARIANT",
    label: "Simple Product, No Variants",
  },
  {
    id: 2,
    key: "PRODUCT_WITH_VARIANT",
    label: "Product With Variants",
  },
  {
    id: 3,
    key: "COMPOSITE_PRODUCT",
    label: "Composite Product",
  },
];
const variants = [
  {
    combination: [],
    sku: "",
    barcode: "",
    costPrice: 0,
    retailPrice: 0,
    retailPriceInclTax: 0,
    tax: 0,
    taxId: "",
    discount: 0,
    discountTypeId: null,
  },
];
function AddProduct() {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(
    productAction[0]?.key || null,
  );
  const [uploadedUrl, setUploadedUrl] = useState();
  const [tempVariant, setTempVariant] = useState([variants]);
  const [id, setId] = useState(location.state?.id ? location?.state?.id : null);
  const [productTypes, setProductTypes] = useState(null);
  const [brands, setBrands] = useState(null);
  const [outlets, setOutlets] = useState(null);
  const [barcodeTypes, setbarcodeTypes] = useState(null);
  const [tags, setTags] = useState(null);
  const [season, setSeason] = useState(null);
  const [salesChannels, setSalesChannels] = useState(null);
  const [product, setProduct] = useState({});
  const [unit, setUnit] = useState(null);
  const [attributeState, setAttributeState] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fields, setFields] = useState([{ key: 0, type: [], values: [] }]); // `type` and `values` are now arrays for custom input

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchProductDataByIds = async (ids) => {
    try {
      const promises = ids.map((id) => getProductById(id)); // Assuming getProductById is your API function
      const results = await Promise.all(promises);
      // Now you have an array of product data
      const productData = results.map((result) => ({
        value: result.data.data._id,
        label: result.data.data.name,
        ...result.data.data,
      }));
      // Set the product data in the form
      form.setFieldValue("compositeProductsIds", productData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };
  const getProduct = async (id) => {
    try {
      const {
        data: { data },
      } = await getProductById(id);
      if (data) {
        if (data.type == "COMPOSITE_PRODUCT") {
          const compositeProductsIds = data.compositeProducts.map(
            (val) => val.productId,
          );
          fetchProductDataByIds(compositeProductsIds);
          // form.setFieldValue('compositeProductsIds', compositeProductsIds);
          form.setFieldValue("variants", {
            retailPrice: data?.variants[0]?.retailPrice,
            retailPriceInclTax: data?.variants[0]?.retailPriceInclTax,
            taxId: data?.variants[0]?.taxId,
            discountTypeId: null,
          });
          form.setFieldValue("retailPrice", data?.variants[0]?.retailPrice);
          form.setFieldValue("taxId", data?.variants[0]?.taxId);
          form.setFieldValue(
            "retailPriceInclTax",
            data?.variants[0]?.retailPriceInclTax,
          );
        }
        setAttributeState(data.attributes);
        setProduct(data);
        setSelectedProduct(data.type);
        form.setFieldsValue(data);
      }
    } catch {
      ServerError();
    }
  };
  const getAllTax = async () => {
    try {
      const response = await getAllTaxApi("tax");
      return response.data.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const getDiscount = async () => {
    try {
      const response = await getAllListOfValueByKey("discount_type");
      return response.data.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const preField = async () => {
    const [tax, discount] = await Promise.all([getAllTax(), getDiscount()]);
    form.setFieldsValue({
      variants: [
        {
          combination: [],
          sku: "",
          barcode: "",
          costPrice: 0,
          retailPrice: 0,
          retailPriceInclTax: 0,
          tax: 0,
          taxId: tax[0]?._id,
          discount: 0,
          discountTypeId: discount[0]?._id,
        },
      ],
    });
  };
  useEffect(() => {
    getListOfValues();

    if (id) {
      getProduct(id);
      form.setFieldValue("trackInventory", false);
      form.setFieldValue("loyalityPoints", 0);
    } else {
      preField();
    }
  }, []);

  const getListOfValues = async () => {
    try {
      const [
        productTypesRes,
        brandsRes,
        tagsRes,
        seasonRes,
        unitRes,
        barcodeTypeRes,
        salesChannelRes,
        outletRes,
      ] = await Promise.all([
        getAllProductTypeApi(),
        getSlugListOfValuesByKey("brand"),
        getSlugListOfValuesByKey("tag"),
        getSlugListOfValuesByKey("season"),
        getSlugListOfValuesByKey("unit-type"),
        getAllListOfValueByKey("barcode_type"),
        getAllListOfValueByKey("sales_channel"),
        getAllOutletApi(),
      ]);

      // Set state for each response
      setProductTypes(productTypesRes.data.data.data);
      setBrands(brandsRes.data.data.data);
      setTags(tagsRes.data.data.data);
      setSeason(seasonRes.data.data.data);
      setUnit(unitRes.data.data.data);
      setbarcodeTypes(barcodeTypeRes.data.data.data);
      setSalesChannels(salesChannelRes.data.data.data);
      setOutlets(outletRes.data.data.data);
    } catch (err) {
      console.error("Error fetching data", err);
    }
  };

  // Call the API fetching function on component mount

  const handleRadioChange = (key) => {
    setSelectedProduct(key);
  };

  const onFinish = async (values) => {
    let response;
    let payload;
    try {
      if (selectedProduct == "COMPOSITE_PRODUCT") {
        payload = {
          ...values,
          isActive: true,
          images: [values.images],
        };
      } else {
        payload = {
          ...values,
          isActive: true,
        };
      }
      if (selectedProduct == "COMPOSITE_PRODUCT" && !id) {
        payload = {
          ...payload,
          variants: [
            {
              retailPrice: values.retailPrice,
              retailPriceInclTax: values.retailPriceInclTax,
              taxId: values.taxId,
              discount: values.discount,
              discountTypeId: null,
            },
          ],
        };
      }

      if (payload.compositeProductsIds) {
        // const tempComposite = payload.compositeProductsIds.map((val) => JSON.parse(val?.value || {}));
        // const a = payload.compositeProducts.map((product, index) => product.productId == tempComposite[index]._id)
      }
      payload.type = selectedProduct;
      payload.attributes = attributeState;
      if (id) {
        response = await updateProduct(payload, id);
      } else {
        response = await createProduct(payload);
      }
      if (response.data.meta.success) {
        form.resetFields();
        openNotification("success", "Product added successfully");
        navigate("../products");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  const onFinishVarients = async (values) => {
    const [tax, discount] = await Promise.all([getAllTax(), getDiscount()]);
    const combinations = generateCombinations(fields);
    const variants = combinations.map((combination) => ({
      combination,
      sku: "",
      barcode: "",
      costPrice: 0,
      retailPrice: 0,
      retailPriceInclTax: 0,
      tax: 0,
      taxId: tax[0]?._id,
      discount: 0,
      discountTypeId: discount[0]?._id,
    }));

    // Set the form value for variants
    form.setFieldsValue({ variants });
    closeModal();
  };

  const generateCombinations = (fields) => {
    const combinations = fields
      .filter((field) => field.type.length > 0 && field.values.length > 0)
      .map((field) => field.values);

    // Create all possible combinations
    const product = (...sets) =>
      sets.reduce(
        (acc, set) => acc.flatMap((x) => set.map((y) => [...x, y])),
        [[]],
      );

    return product(...combinations);
  };

  return (
    <main className="mt-6 text-left flex-1 overflow-y-scroll scrollbar">
      <CustomModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        heading={"Add/Edit Variants"}
        className="max-w-[50%]"
      >
        <AddVariants
          mainForm={form}
          form={form2}
          fields={fields}
          setFields={setFields}
          onFinish={onFinishVarients}
          isModalOpen={isModalOpen}
          product={product}
          setAttributeState={setAttributeState}
        />
      </CustomModal>
      <Form
        form={form}
        scrollToFirstError={true}
        name="descriptionForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <h2 className="sm:text-xl xxl:text-2xl font-medium mb-4">
          Add Product
        </h2>
        <div className="grid gap-4 grid-cols-12">
          <div className="sm:col-span-12 lg:col-span-9">
            <BasicCard heading={"Product Type"}>
              <div className="flex sm:gap-5 xxl:gap-14 mt-3">
                {productAction.map((data, index) => (
                  <div className="inline-flex items-center" key={index}>
                    <label
                      className="relative flex items-center cursor-pointer"
                      htmlFor={`product-radio-${data.key}`}
                    >
                      <input
                        disabled={id?.length ? true : false}
                        name={data.key}
                        type="radio"
                        className="peer w-[1.1rem] aspect-square cursor-pointer appearance-none rounded-full border border-lightGrey checked:border-redOrange transition-all"
                        id={`product-radio-${data.key}`}
                        checked={selectedProduct === data.key}
                        onChange={() => handleRadioChange(data.key)}
                        defaultChecked={productAction[0].key}
                      />
                      <span className="absolute bg-redOrange w-[0.6rem] aspect-square rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-[0.3rem] left-[0.25rem]" />
                      <span
                        className="ml-2 text-lightGrey peer-checked:text-black cursor-pointer text-sm"
                        htmlFor={`product-radio-${data.key}`}
                      >
                        {data.label}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </BasicCard>

            <BasicCard
              heading={"Primary Information"}
              className="addProductInfo primarInformation-Details"
            >
              <Information
                unit={unit}
                barcodeTypes={barcodeTypes}
                form={form}
              />
            </BasicCard>

            <BasicCard heading={"Details"} className="basicCardDetails">
              <Row className="mt-5">
                <Col span={12}>
                  <Form.Item name="askSerialNo" valuePropName="checked">
                    <Checkbox>
                      Serial number enabled product. Prompt cashier to enter
                      serial number at checkout.
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="partOfCompositeProductOnly"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      Part of a composite product, cannot be sold individually.
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Form.Item
                    name="excludeFromDiscountOffer"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      Exclude this product from any and all discount offers.
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="noLoyalityPoints" valuePropName="checked">
                    <Checkbox>No loyalty points for this product.</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </BasicCard>

            <BasicCard heading={"Inventory"} className="basicInventoryDetails">
              <div className="text-ufoGreen opacity-90 mt-3">
                Track inventory for this product
              </div>
              <div className="text-black mt-3 font-medium">
                Would you like Proxy to track inventory movement for this
                product?
              </div>
              <Form.Item
                name="trackInventory"
                label="Track Inventory"
                // rules={[{ required: true, message: "Please select an option" }]}
              >
                <Switch defaultChecked={false} />
              </Form.Item>
            </BasicCard>

            {selectedProduct == "COMPOSITE_PRODUCT" && (
              <BasicCard heading={"Composite Product"}>
                <ComponentProduct form={form} />
              </BasicCard>
            )}
            {selectedProduct == "NO_VARIANT" && (
              <BasicCard heading={"Pricing"}>
                <Pricing form={form} selectedProduct={selectedProduct} />
              </BasicCard>
            )}
            {selectedProduct == "PRODUCT_WITH_VARIANT" && (
              <BasicCard
                heading={"Variants"}
                buttonTxt={"Add/Edit Variant"}
                onClickBtn={openModal}
              >
                <Pricing form={form} selectedProduct={selectedProduct} />
              </BasicCard>
            )}
          </div>

          <div className="sm:col-span-12 lg:col-span-3">
            <UploadImage
              form={form}
              setUploadedUrl={setUploadedUrl}
              uploadedUrl={uploadedUrl}
            />
            <BasicCard heading={"Categorize"} className="addProductCategorize">
              <Category
                productTypes={productTypes}
                brands={brands}
                tags={tags}
                season={season}
                outlets={outlets}
                salesChannels={salesChannels}
              />
            </BasicCard>
          </div>

          <div className="col-span-12">
            <div className="flex items-center justify-end gap-3">
              <Form.Item>
                <input
                  type="button"
                  onClick={() => navigate("../products")}
                  defaultValue="Cancel"
                  className="leading-none block py-4 px-10 bg-veryLightGrey rounded-full text-lightGrey font-medium border border-lightGrey"
                />
              </Form.Item>
              <Form.Item>
                <input
                  type="submit"
                  defaultValue="Save"
                  className="leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default AddProduct;
