import React, { useEffect, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Flex, Form, message, Upload } from "antd";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const UploadImage = ({ form, setUploadedUrl, uploadedUrl }) => {
  const [loading, setLoading] = useState(false);
  const props = {
    name: "file",
    action: `${process.env.REACT_APP_API_BASE_URL}/file/multi/product`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        console.log(info.file.response.data.file);
        setUploadedUrl(info.file.response.data.file);
        form.setFieldValue("images", info.file.response.data.file);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  useEffect(() => {
    if (form.getFieldValue("images")) {
      setUploadedUrl(form.getFieldValue("images"));
    } else {
      setUploadedUrl(null);
    }
  }, [form.getFieldValue("images")]);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
        marginBottom: 0,
      }}
      className="w-full aspect-square object-cover"
      type="button"
    >
      {loading ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined className="w-16 aspect-square flex items-center justify-center bg-veryLightGrey text-lightGrey rounded-full text-2xl" />
      )}
      <div className="text-lg text-lightGrey mt-4">Upload</div>
    </button>
  );
  return (
    <div className="bg-white sm:rounded-xl xxl:rounded-3xl py-5 px-6 mb-4">
      <Flex className="flex items-center justify-center bg-white w-full aspect-square object-cover">
        <Form.Item name="images">
          <Upload {...props} defaultFileList={[]} className="avatar-uploader">
            {uploadedUrl ? (
              <img
                src={uploadedUrl || "/img/no-image.png"}
                alt="avatar"
                className="w-full aspect-square object-cover border border-lightGrey rounded-md"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
      </Flex>
    </div>
  );
};
export default UploadImage;

// import React from 'react'

// function UploadImage() {
//     return (
//         <div className="bg-white rounded-3xl py-5 px-6">
//             <img
//                 src="img/placeholder.png"
//                 alt=""
//                 width=""
//                 height=""
//                 className="w-full aspect-square object-cover"
//             />
//         </div>
//     )
// }

// export default UploadImage
