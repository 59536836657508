import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideBar from "../../shared/sidebar";
import Header from "../../shared/header";
import { PrivateRoutes } from "../../../routes/private";
import { getUserDetails } from "../../../redux/feature/authSlice";
import { useSelector } from "react-redux";

const PrivateLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector(getUserDetails) || {};

  useEffect(() => {
    let userType = user?.userType;
    let getRoutePermission = PrivateRoutes[0].children.find(
      (data) => data.path == location.pathname,
    );
    console.log(getRoutePermission.permission, "======");
    console.log(
      !getRoutePermission.permission.includes(userType),
      "askndksand",
    );
    if (!getRoutePermission.permission.includes(userType)) {
      navigate("../../dashboard");
    }
  }, [location]);
  return (
    <>
      <body class="bg-lightBlue">
        <div id="main" class="p-2 xl:p-3 xxl:p-5 flex gap-4 items-start h-dvh">
          <SideBar />
          <div
            id="main-panel"
            class="flex-1 flex flex-col h-full overflow-hidden"
          >
            <Header />
            <Outlet />
          </div>
        </div>
      </body>
    </>
  );
};

export default PrivateLayout;
