import React, { useState } from "react";
import CustomModal from "../../component/shared/modal";
import SearchInput from "../../component/shared/searchInput";
import CustomTable from "../../component/shared/customTable";
import {
  changeRegisterStatus,
  createRegister,
  deleteRegisterApi,
  updateRegister,
} from "../../services/outlet/index";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import { Form } from "antd";
import AddCashRegister from "../../component/outlet/addCashRegister";
import ActionAccessWrapper from "../../component/shared/actionAccessWrapper";

function Register() {
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const [form] = Form.useForm();

  const onClickStatus = async (row) => {
    try {
      const payload = {
        ...row,
        isActive: !row.isActive,
        parentId: null,
      };
      const { data } = await changeRegisterStatus(row._id, payload);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Status updated successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };
  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteRegisterApi(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onFinish = async (values) => {
    let response;
    try {
      const payload = {
        ...values,
        isActive: true,
      };
      if (id) {
        response = await updateRegister(payload, id);
      } else {
        response = await createRegister(payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Cash Register successfully saved");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };
  const onClickEdit = async (row) => {
    setId(row._id);
    form.setFieldsValue(row);
    setIsModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => (
        <a className="table-edit-link" onClick={() => onClickEdit(row)}>
          {text}
        </a>
      ),
    },
    {
      title: "Receipt No",
      dataIndex: "receiptNo",
      key: "receiptNo",
    },
    {
      title: "Receipt Prefix",
      dataIndex: "receiptPrefix",
      key: "receiptPrefix",
    },
    {
      title: "Receipt Suffix",
      dataIndex: "receiptSuffix",
      key: "receiptSuffix",
    },
    {
      title: "Outlet",
      dataIndex: "outlet",
      key: "outlet",
      render: (_, row) => {
        return row?.outlet?.name;
      },
    },
  ];

  const onClickAdd = async () => {
    openModal();
    setId(null);
    form.resetFields();
    form.setFieldsValue({
      receiptNo: 1,
      receiptPrefix: "SR",
      receiptSuffix: ".",
    });
  };

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:p-3 xxl:p-6 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8 text-left">
          <h2 className="sm:text-xl xxl:text-2xl font-medium flex-1">
            Register Management
          </h2>
          <ActionAccessWrapper permissionKeys={"CreateCashRegisters"}>
            <a
              onClick={onClickAdd}
              id="addOutletBtn"
              className="block capitalize sm:py-3 xxl:py-4 sm:px-4 xxl:px-6 rounded-full leading-none text-bold bg-redOrange text-white"
            >
              Add Register
            </a>
          </ActionAccessWrapper>
          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={"Register"}
            className="max-w-[70%]"
          >
            <AddCashRegister form={form} onFinish={onFinish} />
          </CustomModal>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex flex-col justify-between overflow-auto scrollbar registerManagementDetail-table">
          <CustomTable
            refreshTable={refreshTable}
            columns={columns}
            endPoint={"cash-register/list"}
            search={searchText}
            handleStatus={onClickStatus}
            handleDelete={onClickDelete}
            handleEdit={onClickEdit}
            showActions={true}
            permissions={{
              edit: "UpdateCashRegistersById",
              status: "UpdateCashRegistersById",
              delete: "DeleteCashRegisterById",
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default Register;
