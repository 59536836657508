import React, { useCallback, useEffect, useState } from "react";
import SearchBar from "../../../component/processSales/searchBar";
import ProductListing from "../../../component/processSales/productListing";
import ProcessSidebar from "../../../component/processSales/processSidebar";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import { getAllProductTypeApi } from "../../../services/product/productType";
import { getAllProductsByTypeId } from "../../../services/product";
import { useDebounce } from "../../../hooks/useDebounce";
import { Form } from "antd";
import { dropDownPayloadForAttribute } from "../../../utils/helper";
import { getAllListOfValueByKey } from "../../../services/globalService";
import { getSelectedOutlet } from "../../../redux/feature/outletSlice";
import { useSelector } from "react-redux";
import { getRegisterDetailsApi } from "../../../services/register";
import { useLocation, useNavigate } from "react-router-dom";
import { getSalesById } from "../../../services/sales";

function ProcessSales() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();

  const [customerForm] = Form.useForm();
  const { selectedOutlet } = useSelector(getSelectedOutlet) || {};
  const [productType, setProductType] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedProductType, setSelectedProductType] = useState();
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [tempSelectedProducts, setTempSelectedProducts] = useState([]);
  const [combinationModal, setCombinationModal] = useState(false);
  const debouncedValue = useDebounce(searchText || "");
  const [selecectedAttribute, setSelectedAttribute] = useState([]);
  const [tempAttibute, setTempAttibute] = useState([]);
  const [discountType, setDiscountType] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [register, setRegister] = useState(false);
  const [notes, setNotes] = useState();
  const [salesDetails, setSalesDetails] = useState(false);

  const [id, setId] = useState(location.state?.id ? location?.state?.id : null);

  const onClcikDiscard = () => {
    setTempSelectedProducts([]);
    setSelectedProducts([]);
    customerForm.resetFields();
  };

  const transformData = (originalData) => {
    return originalData.map((item) => ({
      id: item.product._id,
      name: item.product.name,
      images: item.product.images,
      variants: [
        {
          _id: item._id,
          quantity: item.quantity,
          productId: item.productId,
          discount: item.discount,
          price: item.price,
          taxId: item.taxId,
          tax: item.tax,
          total: item.total,
          notes: item.notes,
          discountType: discountType.find(
            (data) => data._id == item.discountTypeId,
          )?.key,
          retailPrice: item.price,
          discountTypeId: item.discountTypeId,
          combination: item.combination,
        },
      ],
    }));
  };

  const getAllProducts = useCallback(
    async (id) => {
      try {
        const {
          data: { data },
        } = await getAllProductsByTypeId(id, debouncedValue, true);
        setProducts(data.data);
      } catch {
        ServerError();
      }
    },
    [debouncedValue],
  );
  useEffect(() => {
    if (selectedProductType || debouncedValue !== null) {
      getAllProducts(selectedProductType?._id, debouncedValue);
    }
  }, [debouncedValue, selectedProductType]); // Trigger the effect on both debouncedValue and selectedProductType changes

  const getProductType = async () => {
    try {
      const {
        data: { data },
      } = await getAllProductTypeApi(true);
      const d = [{ _id: null, name: "All" }];
      d.push(...data.data);
      setProductType(d);
      getAllProducts(null);
    } catch {
      ServerError();
    }
  };

  useEffect(() => {
    getProductType();
  }, []);

  const getAllTax = async () => {
    try {
      const discountResponse = await getAllListOfValueByKey("discount_type");
      setDiscountType(discountResponse.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllTax();
  }, []);

  const onClickProductType = (type) => {
    setTempSelectedProducts([]);
    setSelectedProductType(type); // Set the selected product type and let useEffect handle the API call
  };

  const getTypes = (data) => {
    return data.map((item) => item.type);
  };
  const handleAddSelectedProduct = (newItem) => {
    if (!openRegister) {
      openNotification("error", "Please open register");
      return;
    }
    setLoader(true);
    if (newItem.variants.length > 1) {
      setCombinationModal(true);
      setSelectedAttribute(dropDownPayloadForAttribute(newItem.attributes));
      setTempAttibute(newItem.attributes);
      form.setFieldsValue({
        attribute: getTypes(newItem.attributes),
        name: newItem.name,
        quantity: 1,
      });
      const updatedListing = tempSelectedProducts;
      updatedListing.push(newItem);
      updatedListing.forEach((product) => {
        product.variants.forEach((variant) => {
          variant.discountType = discountType.find(
            (data) => data._id == variant.discountTypeId,
          ).key;
          variant.productId = product._id;
        });
      });
      setLoader(false);
      setTempSelectedProducts(updatedListing);
    } else {
      setSelectedAttribute(dropDownPayloadForAttribute(newItem.attributes));
      setTempAttibute(newItem.attributes);

      const hasMatchingVariantId = () => {
        return selectedProducts.some((product) =>
          product.variants.some(
            (variant) => variant._id == newItem.variants[0]._id,
          ),
        );
      };
      if (hasMatchingVariantId()) {
        const samaProduct = selectedProducts.find((product) =>
          product.variants.some(
            (variant) => variant._id == newItem.variants[0]._id,
          ),
        );
        const sameProductVarientID = samaProduct.variants[0]._id;
        samaProduct.variants[0].quantity =
          Number(samaProduct.variants[0].quantity) + Number(1);
        const updatedProducts = deleteProductByVariantId(
          selectedProducts,
          sameProductVarientID,
        );
        updatedProducts.push(samaProduct);
        updatedProducts.forEach((product) => {
          product.variants.forEach((variant) => {
            variant.discountType = discountType.find(
              (data) => data._id == variant.discountTypeId,
            )?.key;
            variant.discount = 0;
          });
        });

        setLoader(false);
        setSelectedProducts(updatedProducts);
        onClickCloseCombination();
      } else {
        const updatedProduct = selectedProducts;
        newItem.variants[0].quantity = Number(1);
        updatedProduct.push(newItem);
        updatedProduct.forEach((product) => {
          product.variants.forEach((variant) => {
            variant.discountType = discountType.find(
              (data) => data._id == variant.discountTypeId,
            )?.key;
            variant.discount = 0;
            variant.productId = product._id;
          });
        });
        setLoader(false);
        console.log(updatedProduct, "aslkndsjakds");
        setSelectedProducts(updatedProduct);
      }
    }
  };

  const onClickOpenCombination = () => {
    setCombinationModal(true);
  };

  const onClickCloseCombination = () => {
    setCombinationModal(false);
    form.resetFields();
  };

  const deleteProductByVariantId = (products, variant_id) => {
    return products.filter(
      (product) =>
        !product.variants.some((variant) => variant._id === variant_id),
    );
  };

  const onFinishListingModal = (values) => {
    const outputObject = values?.attribute.reduce((acc, key) => {
      if (key in values) {
        acc[key] = values[key];
      }
      return acc;
    }, {});
    const combinationToMatch = Object.values(outputObject);

    const products = tempSelectedProducts.find(
      (product) => product.name == values.name,
    );

    const matchingProduct = products.variants.find(
      (product) =>
        JSON.stringify(product.combination) ===
        JSON.stringify(combinationToMatch),
    );
    const hasMatchingVariantId = () => {
      return selectedProducts.some((product) =>
        product.variants.some((variant) => variant._id == matchingProduct._id),
      );
    };
    if (hasMatchingVariantId()) {
      const samaProduct = selectedProducts.find((product) =>
        product.variants.some((variant) => variant._id == matchingProduct._id),
      );
      const sameProductVarientID = samaProduct.variants[0]._id;
      samaProduct.variants[0].quantity =
        Number(samaProduct.variants[0].quantity) + 1;
      const updatedProducts = deleteProductByVariantId(
        selectedProducts,
        sameProductVarientID,
      );
      updatedProducts.push(samaProduct);
      setSelectedProducts(updatedProducts);
      onClickCloseCombination();
    } else {
      matchingProduct.quantity = Number(values.quantity);
      matchingProduct.discount = 0;
      const payload = {
        ...products,
        variants: [matchingProduct],
      };
      const updatedItem = selectedProducts;
      updatedItem.push(payload);
      setSelectedProducts(updatedItem);
      onClickCloseCombination();
    }
  };

  const getRegisterDetails = async () => {
    try {
      const { data: response } = await getRegisterDetailsApi(
        selectedOutlet._id,
      );
      if (response.data._id) {
        setRegister(response.data);
        setOpenRegister(true);
      } else {
        setOpenRegister(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getRegisterDetails();
  }, []);

  const getSale = async (id) => {
    try {
      const {
        data: { data },
      } = await getSalesById(id);
      setNotes(data.notes);
      const transformedData = transformData(data.saleDetails);
      setSalesDetails(data);
      if (data.customerId) {
        customerForm.setFieldsValue({
          customerId: {
            label: data.customer.fullName,
            value: data.customer._id,
          },
        });
      }
      setLoader(false);
      setSelectedProducts(transformedData);
    } catch (err) {
      ServerError();
    }
  };

  useEffect(() => {
    if (id && openRegister && discountType) {
      getSale(id);
    }
  }, [id, openRegister, discountType]);

  return (
    <main className="mt-4 flex-1 flex flex-col xlg:overflow-hidden overflow-y-scroll scrollbar">
      <div className="grid grid-cols-12 grid-flow-row-dense sm:gap-4 xxl:gap-10 xlg:overflow-hidden overflow-visible h-full">
        <div className="col-span-12 col-auto xlg:!col-span-8 flex flex-col xlg:overflow-hidden overflow-visible">
          <div className="flex items-center justify-start mb-3">
            <div className="xl:text-xl xxl:text-2xl font-medium">
              {selectedOutlet?.name}
            </div>
            <div className="mx-5">
              <svg
                width={17}
                height={16}
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="xl:w-3 xl:h-3 xxl:w-4 xxl:h-4"
              >
                <path
                  d="M1 14.9999L8 7.99996L1 0.999979"
                  stroke="#BABABA"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 15L16 7.99999L9 1.00001"
                  stroke="black"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="xl:text-xl xxl:text-2xl font-bold">
              {register?.cashRegister?.name}
            </div>
          </div>
          <SearchBar setSearchText={setSearchText} />
          <div className="block border-t border-lightGrey mt-3 pt-0 min-h-96 xlg:overflow-visible overflow-y-auto scrollbar h-full">
            <ul className="flex items-center justify-start gap-2.5 p-2 mb-2 sticky top-0 bg-lightBlue">
              {productType.map((type, index) => {
                return (
                  <li
                    onClick={() => onClickProductType(type)}
                    key={index + 1}
                    className={`${type?._id == selectedProductType?._id ? "active" : ""} w-max border border-veryLightGrey bg-white rounded-full text-sm text-lightGrey leading-none py-1 pl-1 pr-4 [&.active]:bg-brightUFOGreen [&.active]:text-ufoGreen [&.active]:border-ufoGreen [&.active]:font-bold cursor-pointer flex items-center leading-none`}
                  >
                    <div className="w-6 h-6 mr-2 border flex items-center justify-center rounded-full overflow-hidden">
                      <img
                        className="flex-1 w-5 aspect-square"
                        src={type.image || "/img/no-image.png"}
                        alt=""
                      />
                    </div>
                    <span class="flex-1">{type.name}</span>
                  </li>
                );
              })}
            </ul>
            <ProductListing
              products={products}
              handleAddSelectedProduct={handleAddSelectedProduct}
              onClickCloseCombination={onClickCloseCombination}
              combinationModal={combinationModal}
              form={form}
              onFinishListingModal={onFinishListingModal}
              selecectedAttribute={selecectedAttribute}
              tempAttibute={tempAttibute}
            />
          </div>
        </div>
        <div className="col-span-12 xlg:!col-span-4 xlg:overflow-hidden h-full">
          <div class="h-full rounded-3xl">
            <ProcessSidebar
              selectedProductState={selectedProducts}
              setSelectedProductState={setSelectedProducts}
              loader={loader}
              openRegister={openRegister}
              customerForm={customerForm}
              onClcikDiscard={onClcikDiscard}
              salesDetails={salesDetails}
              setSalesDetails={setSalesDetails}
              notes={notes}
              setNotes={setNotes}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default ProcessSales;
