import React, { useState } from "react";
import { Tabs } from "antd";
import { useEffect } from "react";
import ReportingRegister from "../../../component/reporting/sales/register";
import ReportSalesCategory from "../../../component/reporting/sales/category";
import SalesInstruction from "../../../component/reporting/sales/salesInstruction";
import SalesByItem from "../../../component/reporting/sales/salesByItem";
import UserRepoting from "../../../component/reporting/sales/user";
import CustomerTier from "../../../component/reporting/sales/customerTier";
import { getAllOutletApi } from "../../../services/outlet";
import SaleSummary from "../../../component/reporting/sales/saleSummary";
import { getUserPermissions } from "../../../redux/feature/authSlice";
import { useSelector } from "react-redux";

function SalesReporting() {
  const [outlet, setOutlet] = useState([]);
  const allowedPermissionFromStore = useSelector(getUserPermissions) || {};
  const [tabWithPermission, setTabWithPermission] = useState([]);

  const tabData = [
    {
      key: "1",
      label: "Sales Summary",
      children: <SaleSummary outlet={outlet} />,
      permission: "GetSalesSummary",
    },
    {
      key: "2",
      label: "Sales (By Items)",
      children: <SalesByItem outlet={outlet} />,
      permission: "GetSalesRegister",
    },
    {
      key: "3",
      label: "Sales Transaction",
      children: <SalesInstruction />,
      permission: "GetSalesTransactions",
    },
    {
      key: "5",
      label: "Sales By Type",
      children: <ReportSalesCategory outlet={outlet} />,
      permission: "GetSalesByTypes",
    },
    {
      key: "6",
      label: "Registers",
      children: <ReportingRegister outlet={outlet} />,
      permission: "GetSalesRegister",
    },
    {
      key: "7",
      label: "Users",
      children: <UserRepoting outlet={outlet} />,
      permission: "GetSalesByUser",
    },
    {
      key: "8",
      label: "Customer Groupe (Tiers)",
      children: <CustomerTier outlet={outlet} />,
      permission: "GetSalesSummary",
    },
  ];

  useEffect(() => {
    if (allowedPermissionFromStore.masterUser == true) {
      setTabWithPermission(tabData);
    } else {
      const userPermissions = new Set(
        allowedPermissionFromStore?.permissions.map((p) => p.key),
      );
      const filteredTabs = tabData.filter((tab) =>
        userPermissions.has(tab.permission),
      );

      setTabWithPermission(filteredTabs);
    }
  }, []);

  const getOutlet = async () => {
    try {
      const { data: response } = await getAllOutletApi();
      const { data: record } = response;
      setOutlet(record?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOutlet();
  }, []);

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:px-3 xxl:px-6 sm:py-4 xxl:py-8 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8">
          <h2 className="sm:text-xl xxl:text-2xl font-medium">Sales</h2>
          <div className="flex items-center gap-6">
            <div className="relative">
              <div className="flex items-center gap-1 leading-none block py-2 px-4 xxl:py-4 xxl:px-6 bg-redOrange rounded-full text-white font-medium border border-redOrange cursor-pointer uppercase flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#ffffff"
                  viewBox="0 0 256 256"
                >
                  <path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0V156.69l50.34-50.35a8,8,0,0,1,11.32,0L128,132.69,180.69,80H160a8,8,0,0,1,0-16h40a8,8,0,0,1,8,8v40a8,8,0,0,1-16,0V91.31l-58.34,58.35a8,8,0,0,1-11.32,0L96,123.31l-56,56V200H224A8,8,0,0,1,232,208Z"></path>
                </svg>
                <span className="uppercase">Export</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#ffffff"
                  viewBox="0 0 256 256"
                >
                  <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-auto scrollbar">
          <Tabs
            defaultActiveKey="1"
            className="filtersTabMain"
            items={tabWithPermission}
          />
        </div>
      </div>
    </main>
  );
}

export default SalesReporting;
