import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import CurrentInventory from "../../../component/reporting/inventory/currentInventory";
import { getAllOutletApi } from "../../../services/outlet";
import { getSlugListOfValuesByKey } from "../../../services/globalService";
import { getAllProductTypeApi } from "../../../services/product/productType";
import InventoryPurchase from "../../../component/reporting/inventory/inventoryPurchase";
import InventoryAdjustment from "../../../component/reporting/inventory/invnetoryAdjustment";
import COGS from "../../../component/reporting/inventory/cogs";
import { getUserPermissions } from "../../../redux/feature/authSlice";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

const onChange = (key) => {
  console.log(key);
};

function InventoryReporting() {
  const allowedPermissionFromStore = useSelector(getUserPermissions) || {};
  const [productTypes, setProductTypes] = useState(null);
  const [brands, setBrands] = useState(null);
  const [outlets, setOutlets] = useState(null);
  const [season, setSeason] = useState(null);
  const [tabWithPermission, setTabWithPermission] = useState([]);

  const [tags, setTags] = useState(null);

  const tabData = [
    {
      key: "1",
      label: "Current Inventory",
      children: (
        <CurrentInventory
          season={season}
          tags={tags}
          brands={brands}
          productTypes={productTypes}
        />
      ),
      permission: "GetCurrentInventory",
    },
    {
      key: "2",
      label: "Inventory Purchase",
      children: <InventoryPurchase outlet={outlets} />,
      permission: "GetInventoryPurchase",
    },
    {
      key: "3",
      label: "Inventory Adjustment",
      children: (
        <InventoryAdjustment
          season={season}
          tags={tags}
          brands={brands}
          productTypes={productTypes}
          outlet={outlets}
        />
      ),
      permission: "GetInventoryLedger",
    },
    {
      key: "4",
      label: "Cost Of Goods Sold (Cogs)",
      children: <COGS outlet={outlets} />,
      permission: "GetCOGS",
    },
  ];
  useEffect(() => {
    if (allowedPermissionFromStore.masterUser == true) {
      setTabWithPermission(tabData);
    } else {
      const userPermissions = new Set(
        allowedPermissionFromStore?.permissions.map((p) => p.key),
      );
      const filteredTabs = tabData.filter((tab) =>
        userPermissions.has(tab.permission),
      );

      setTabWithPermission(filteredTabs);
    }
  }, []);
  const getListOfValues = async () => {
    try {
      const [productTypesRes, brandsRes, tagsRes, seasonRes, outletRes] =
        await Promise.all([
          getAllProductTypeApi(),
          getSlugListOfValuesByKey("brand"),
          getSlugListOfValuesByKey("tag"),
          getSlugListOfValuesByKey("season"),
          getAllOutletApi(),
        ]);

      setProductTypes(productTypesRes.data.data.data);
      setBrands(brandsRes.data.data.data);
      setTags(tagsRes.data.data.data);
      setSeason(seasonRes.data.data.data);
      setOutlets(outletRes.data.data.data);
    } catch (err) {
      console.error("Error fetching data", err);
    }
  };

  useEffect(() => {
    getListOfValues();
  }, []);

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:px-3 xxl:px-6 sm:py-4 xxl:py-8 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8">
          <h2 className="sm:text-xl xxl:text-2xl font-medium">Inventory</h2>
        </div>

        <div className="overflow-auto scrollbar">
          <Tabs
            defaultActiveKey="1"
            className="filtersTabMain"
            items={tabWithPermission}
          />

          {/* <Tabs
            defaultActiveKey="1"
            className="filtersTabMain"
            onChange={onChange}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <TabPane tab="Current Inventory" key="1">
              <CurrentInventory
                season={season}
                tags={tags}
                brands={brands}
                productTypes={productTypes}
              />
            </TabPane>

            <TabPane tab="Inventory Purchase" key="2">
              <InventoryPurchase outlet={outlets} />
            </TabPane>

            <TabPane tab="Inventory Adjustment" key="3">
              <InventoryAdjustment
                season={season}
                tags={tags}
                brands={brands}
                productTypes={productTypes}
                outlet={outlets}
              />
            </TabPane>

            <TabPane tab="Cost Of Goods Sold (Cogs)" key="4">
              <COGS outlet={outlets} />
            </TabPane>
          </Tabs> */}
        </div>
      </div>
    </main>
  );
}

export default InventoryReporting;
