import React, { useEffect, useState } from "react";
import { Input, Select, Tooltip, Row, Col, Button, Popover } from "antd";
import {
  inventoryType,
  productTypeWithAllFilter,
} from "../../../component/shared/contants";
import { getAllOutletApi } from "../../../services/outlet";
import { dropDownPayload } from "../../../utils/helper";
import {
  getAllProductAddAndSet,
  updateAllProductInventorry,
  updateInventorryLedger,
} from "../../../services/inventory";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import ActionAccessWrapper from "../../../component/shared/actionAccessWrapper";
import { useNavigate } from "react-router-dom";

function InventoryAddSet() {
  const [refresh, setRefresh] = useState(false);
  const [outlet, setOutlet] = useState([]);
  const [allRecord, setRecord] = useState([]);
  const [filter, setFilter] = useState({
    productType: "",
    outletId: "",
    inventoryType: "",
  });
  const navigate = useNavigate();
  const getOutlet = async () => {
    try {
      const { data: response } = await getAllOutletApi();
      const { data: record } = response;
      record.data.unshift({ _id: 0, name: "ALL" });
      setOutlet(record?.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getOutlet();
  }, []);

  const generateQueryParams = (obj) => {
    return Object.entries(obj)
      .filter(([key, value]) => value) // Exclude keys with empty values
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      ) // Encode key-value pairs
      .join("&"); // Join them with "&" to form the query string
  };

  const getAddData = async () => {
    try {
      const { data: response } = await getAllProductAddAndSet(
        generateQueryParams(filter),
      );
      const { data: record } = response;
      setRecord(record);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAddData();
  }, [filter, refresh]);

  const handleEdit = (value, recordKey, columnKey) => {
    setRecord((prevData) =>
      prevData.map((row) =>
        row.productId === recordKey.productId
          ? {
              ...row,
              [columnKey]: value,
            }
          : row,
      ),
    );
  };

  const showNotes = (record, colum) => {
    if (!record.extraQuantity) {
      openNotification("error", "Quantity is not added");
    } else {
      record[colum] = !record[colum];
    }
  };

  const onClickSet = async (record) => {
    if (!record.extraQuantity) {
      openNotification("error", "Quantity is not added");
    } else {
      try {
        const updatedPayload = {
          productId: record.productId,
          quantity: record.extraQuantity,
          notes: record.notes,
        };
        const response = await updateAllProductInventorry(
          [updatedPayload],
          filter.outletId,
        );
        if (response.data.meta.success) {
          setRefresh(!refresh);
          openNotification(
            "success",
            "✅ New stock added Updated Successfully",
          );
        } else {
          openNotification("error", response.data.error);
        }
      } catch (err) {
        console.log(err);
        ServerError();
      }
    }
  };
  const onClickAdd = async (record) => {
    if (!record.extraQuantity) {
      openNotification("error", "Quantity is not added");
    } else {
      try {
        const updatedPayload = {
          productId: record.productId,
          quantity: record.extraQuantity,
          notes: record.notes,
          outletId: filter.outletId,
        };
        const response = await updateInventorryLedger(updatedPayload);
        if (response.data.meta.success) {
          setRefresh(!refresh);
          openNotification("success", "✅ New stock added successfully");
        } else {
          openNotification("error", response.data.error);
        }
      } catch (err) {
        console.log(err);
        ServerError();
      }
    }
  };

  const onClickEdit = (row) => {
    navigate("../products/add-product", { state: { id: row.productId } });
  };

  const renderTableRows = () =>
    allRecord?.map((record, index) => (
      <tr key={index}>
        <td className="row-custom-table-body text-left !p-2">
          <Tooltip
            title={
              <>
                <Row>Product Name: {record?.name}</Row>
                <Row>SKU: {record?.skuNo}</Row>
                <Row>Product Sku: {record?.barcode}</Row>
                <Row>Re-Order Level: {record?.reOrderLevel}</Row>
              </>
            }
          >
            <span
              className="cursor-pointer text-decoration-underline"
              onClick={() => onClickEdit(record)}
            >
              {record.name}
            </span>
          </Tooltip>
        </td>
        <td className="row-custom-table-body bg-[#f2fdff] !p-2">
          {record.onHand}
        </td>
        <td className="row-custom-table-body bg-[#fff8ed] !p-2">
          {record.commited}
        </td>
        <td className="row-custom-table-body bg-[#f6ffed] !p-2">
          {record.available}
        </td>
        <td className="row-custom-table-body !p-2">
          <Row className="flex justify-center items-center gap-2">
            <Col spam={8}>
              <ActionAccessWrapper permissionKeys={"UpdateInventoryBalance"}>
                <Popover
                  // visible={record.visibleA === true}
                  trigger="click"
                  content={
                    <Row>
                      <div className="relative w-full h-auto">
                        <Input
                          className="w-full"
                          placeholder="Notes"
                          value={allRecord[index]?.notes}
                          onChange={(e) =>
                            handleEdit(e.target.value, record, "notes")
                          }
                        />
                        <button
                          disabled={filter.outletId?.length ? false : true}
                          onClick={() => onClickAdd(record)}
                          className="px-2 py-1 bg-gray-200 rounded mt-5 w-full"
                        >
                          Confirm
                        </button>
                      </div>
                    </Row>
                  }
                  title="Notes"
                >
                  <Button
                    onClick={() => showNotes(record, "visibleA")}
                    disabled={filter.outletId?.length ? false : true}
                    className="px-2 py-1 border rounded"
                  >
                    Add
                  </Button>
                </Popover>
              </ActionAccessWrapper>
            </Col>
            <Col spam={8}>
              <Input
                className="w-32"
                placeholder="Extra Quantity"
                value={allRecord[index]?.extraQuantity}
                onChange={(e) =>
                  handleEdit(e.target.value, record, "extraQuantity")
                }
              />
            </Col>
            <Col spam={8}>
              <ActionAccessWrapper permissionKeys={"UpdateInventoryLedger"}>
                <Popover
                  // visible={record.visibleB === true}
                  trigger="click"
                  content={
                    <Row>
                      <div className="relative w-full h-auto">
                        <Input
                          className="w-full"
                          placeholder="Notes"
                          value={allRecord[index]?.notes}
                          onChange={(e) =>
                            handleEdit(e.target.value, record, "notes")
                          }
                        />
                        <button
                          disabled={filter.outletId?.length ? false : true}
                          onClick={() => onClickSet(record)}
                          className="px-2 py-1 bg-gray-200 rounded mt-5 w-full"
                        >
                          Confirm
                        </button>
                      </div>
                    </Row>
                  }
                  title="Notes"
                >
                  <button
                    disabled={filter.outletId?.length ? false : true}
                    onClick={() => showNotes(record, "visibleB")}
                    className="px-2 py-1 bg-gray-200 rounded"
                  >
                    Set
                  </button>
                </Popover>
              </ActionAccessWrapper>
            </Col>
          </Row>
        </td>
        <td className="row-custom-table-body !p-2">
          <Tooltip
            className="text-center"
            overlayClassName="locationTooltip"
            title={
              <Row>
                <div className="relative w-full h-auto">
                  <h3 className="text-lg font-medium">Location/s</h3>
                  <div className="py-4 px-5 bg-white">
                    <table className="w-full">
                      <tbody>
                        {record.breakdown.map((data) => {
                          return (
                            <tr>
                              <td>{data?.outletName}</td>
                              <td className="bg-[#f2fdff]">
                                {data?.available}
                              </td>
                              <td className="bg-[#fff8ed]">{data?.commited}</td>
                              <td className="bg-[#f6ffed]">{data?.onHand}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Row>
            }
          >
            <p>{record.outletName}</p>
          </Tooltip>
        </td>
      </tr>
    ));

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 flex flex-col overflow-hidden">
      <div className="sm:p-3 xxl:p-6 bg-white rounded-3xl h-full flex flex-col">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8">
          <h2 className="sm:text-xl xxl:text-2xl font-medium">My Inventory</h2>
          <div className="flex items-center gap-4">
            <Select
              className="w-52"
              options={inventoryType}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) =>
                setFilter((prev) => ({ ...prev, inventoryType: value }))
              }
              placeholder="Select Inventory Type"
            />
            <Select
              className="w-52"
              options={productTypeWithAllFilter}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) =>
                setFilter((prev) => ({ ...prev, productType: value }))
              }
              placeholder="Select Product Type"
            />
            <Select
              className="w-52"
              options={dropDownPayload(outlet)}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) =>
                setFilter((prev) => ({ ...prev, outletId: value }))
              }
              placeholder="Select Outlet"
            />
          </div>
        </div>
        <div className="flex flex-col justify-between overflow-auto scrollbar purchanseListing-table">
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="custom-table-dic-header">
                <th className="px-4 py-2 font-medium text-left">
                  Inventory Item
                </th>
                <th className="px-4 py-2 font-medium">On Hand</th>
                <th className="px-4 py-2 font-medium">Commited</th>
                <th className="px-4 py-2 font-medium">Available</th>
                <th className="px-4 py-2 font-medium">
                  Edit Available Quantity
                </th>
                <th className="px-4 py-2 font-medium">Outlets</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
        </div>
      </div>
    </main>
  );
}

export default InventoryAddSet;
