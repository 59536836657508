import React, { useState } from "react";
import FilterModal from "../../../component/products/filterModal";
import Filter from "../../../component/products/filter";
import CustomTable from "../../../component/shared/customTable";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import {
  deleteinventoryTransferById,
  updateinventoryTransfer,
} from "../../../services/inventory/transfer";
import ActionAccessWrapper from "../../../component/shared/actionAccessWrapper";

function InventoryTransfer() {
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);

  const onClose = () => {
    setIsFilterOpen(false);
  };

  const columns = [
    {
      title: "Transfer ID",
      dataIndex: "transferId",
      key: "transferId",
      render: (text, row) => (
        <a className="table-edit-link" onClick={() => onClickEdit(row)}>
          {text}
        </a>
      ),
    },
    {
      title: "Transfer Date",
      dataIndex: "transferDate",
      key: "transferDate",
      render: (_, row) => <p>{new Date(row.transferDate).toDateString()}</p>,
    },
    {
      title: "To Outlet",
      dataIndex: "toOutlet",
      key: "toOutlet",
      render: (_, row) => {
        return row?.toOutlet.name;
      },
    },
    {
      title: "From Outlet",
      dataIndex: "fromOutlet",
      key: "fromOutlet",
      render: (_, row) => {
        return row?.fromOutlet.name;
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, row) => {
        return row?.createdBy?.fullName;
      },
    },
    {
      title: "Modified By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (_, row) => {
        return row?.updatedBy?.fullName;
      },
    },
  ];

  const onClickEdit = (row) => {
    navigate("../inventory/inventor-transfer/add", { state: { id: row._id } });
  };

  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteinventoryTransferById(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onClickStatus = async (row) => {
    const payload = {
      ...row,
      isActive: !row.isActive,
    };
    try {
      const response = await updateinventoryTransfer(payload, row._id);
      if (
        response.data.meta.status == 201 ||
        response.data.meta.status == 200
      ) {
        message.success(`Successfully Updated`);
        setRefreshTable((prev) => !prev);
      }
    } catch (err) {
      message.success(`Error occured`);
      console.log(err);
    }
  };
  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 flex flex-col overflow-hidden">
      <div className="sm:p-3 xxl:p-6 bg-white rounded-3xl h-full flex flex-col">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8">
          <h2 className="sm:text-xl xxl:text-2xl font-medium">
            Inventory Transfer
          </h2>
          <div className="flex items-center justify-end gap-6">
            <ActionAccessWrapper permissionKeys={"CreateInventoryTransfer"}>
              <Link to="/inventory/inventor-transfer/add">
                <a className="block capitalize sm:py-3 xxl:py-4 sm:px-4 xxl:px-6 min-w-36 text-center rounded-full leading-none text-bold bg-redOrange text-white">
                  Add Transfer Invnetory
                </a>
              </Link>
            </ActionAccessWrapper>
          </div>
        </div>
        <form action="" className="relative mb-6">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Find Inventory"
            className="block leading-none py-3 pr-10 pl-7 border w-full rounded-full"
          />
          {/* <button
            type="button"
            onClick={() => setIsFilterOpen(true)}
            className="productFilterBtn absolute right-0 top-0 bg-veryLightGrey h-full rounded-r-full flex items-center justify-center min-w-32 border border-lightBlue text-lightGrey font-medium [&.active]:bg-redOrange [&.active]:text-white [&.active]:border-redOrange"
          >
            More Filters
          </button> */}
          <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
          <FilterModal isModalOpen={isFilterOpen} onClose={onClose}>
            <Filter />
          </FilterModal>
        </form>
        {/* Records */}
        <div className="flex flex-col justify-between inventoryTransferListing-table overflow-auto scrollbar">
          <CustomTable
            columns={columns}
            endPoint={"inventory-transfer"}
            search={searchText}
            handleEdit={onClickEdit}
            handleDelete={onClickDelete}
            showActions={true}
            refreshTable={refreshTable}
            permissions={{
              edit: "UpdateInventoryTransferById",
              status: "UpdateInventoryTransferById",
              delete: "DeleteInventoryTransferById",
            }}
          />
        </div>
      </div>
    </main>
  );
}

export default InventoryTransfer;
