import { Col, Row, Select, Collapse, Table } from "antd";
import { useEffect, useState } from "react";
import CustomDateRange from "../../../shared/customDateRange";
import { getLedgerReport } from "../../../../services/reporting";
import { dropDownPayload } from "../../../../utils/helper";
import moment from "moment";

const { Panel } = Collapse;

function InventoryAdjustment({ season, tags, brands, productTypes }) {
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, row) => (
        <p>{moment(row?.createdAt).format("MMMM Do YYYY hh:mm:ss")}</p>
      ),
    },
    {
      title: "User",
      render: (res) => {
        return res.createdBy?.fullName || "N/A";
      },
    },
    {
      title: "Adjustment Reason",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "In",
      dataIndex: "quantityIn",
      key: "quantityIn",
    },
    {
      title: "Out",
      dataIndex: "quantityOut",
      key: "quantityOut",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
  ];
  const [searchText, setSearchText] = useState("");
  const [dates, setDates] = useState(null);
  const [ledgerData, setLedgerData] = useState("");
  const [filterValue, setFilterValue] = useState({
    inventoryType: "",
    productType: "",
    seasonIds: "",
    tagIds: "",
    brandIds: "",
    productTypeIds: "",
  });

  const handleChange = (value) => {
    setDates(value);
    if (value) {
      const startDate = value[0].toString();
      const endDate = value[1].toString();
      setFilterValue((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
      }));
    }
  };

  const onChangeType = (options, key) => {
    setFilterValue((prevState) => ({
      ...prevState,
      [key]: options,
    }));
  };
  const onChangeProductType = (options) => {
    setFilterValue((prevState) => ({
      ...prevState,
      productTypeIds: options.map((id) => id), // Extract IDs from selected outlets
    }));
  };

  const onChangeBrand = (options) => {
    console.log(
      options.map((id) => id),
      "kjdjksadjkasbd",
    );
    setFilterValue((prevState) => ({
      ...prevState,
      brandIds: options.map((id) => id), // Extract IDs from selected outlets
    }));
  };

  const onChangeTags = (options) => {
    setFilterValue((prevState) => ({
      ...prevState,
      tagIds: options.map((id) => id), // Extract IDs from selected outlets
    }));
  };

  const onChangeSeason = (options) => {
    setFilterValue((prevState) => ({
      ...prevState,
      seasonIds: options.map((id) => id), // Extract IDs from selected outlets
    }));
  };

  useEffect(() => {
    setFilterValue((prevState) => ({
      ...prevState,
    }));
  }, []);

  const getLedger = async () => {
    try {
      const response = await getLedgerReport(filterValue);
      setLedgerData(response.data?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getLedger();
  }, [filterValue]);

  return (
    <>
      {/* <div className="flex items-center gap-6 mb-4">
                <form action="" className="relative w-2/5">
                    <input
                        type="text"
                        placeholder="Find ..."
                        onChange={(e) => setSearchText(e.target.value)}
                        className="block leading-none py-2 xxl:py-3 pr-6 xxl:pr-10 pl-12 xxl:pl-12 border w-full rounded-full"
                    />
                    <div className="absolute top-2 xxl:top-2 left-4 xxl:left-4 w-6 h-6">
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M19.7555 18.6065L16.3182 15.2458L16.2376 15.1233C16.0878 14.9742 15.883 14.8902 15.6692 14.8902C15.4554 14.8902 15.2505 14.9742 15.1007 15.1233C12.1795 17.8033 7.67815 17.949 4.58201 15.4637C1.48586 12.9784 0.755668 8.63337 2.87568 5.31017C4.9957 1.98697 9.30807 0.716847 12.9528 2.34214C16.5976 3.96743 18.4438 7.98379 17.267 11.7276C17.1823 11.9981 17.2515 12.2922 17.4487 12.4992C17.6459 12.7062 17.9411 12.7946 18.223 12.7311C18.505 12.6676 18.7309 12.4619 18.8156 12.1914C20.2224 7.74864 18.0977 2.96755 13.8161 0.941058C9.53449 -1.08544 4.38084 0.250824 1.68905 4.08542C-1.00273 7.92001 -0.424821 13.1021 3.04893 16.2795C6.52268 19.4569 11.8498 19.6759 15.5841 16.7949L18.6277 19.7705C18.942 20.0765 19.4502 20.0765 19.7645 19.7705C20.0785 19.4602 20.0785 18.9606 19.7645 18.6503L19.7555 18.6065Z"
                                fill="black"
                            />
                        </svg>
                    </div>
                </form>
                <div className="flex items-center flex-wrap gap-4 w-3/5">
                    <div className="flex items-center gap-2">
                        <label className="inline-flex items-center align-sub justify-end cursor-pointer relative">
                            <input
                                type="checkbox"
                                className="peer h-[1.4rem] aspect-square cursor-pointer transition-all appearance-none rounded-md border checked:bg-ufoGreen checked:border-ufoGreen"
                            />
                            <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3.5 w-3.5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    stroke="currentColor"
                                    stroke-width="1"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </span>
                        </label>
                        <div className="block text-black text-md text-center">
                            Group by brand
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <label className="inline-flex items-center align-sub justify-end cursor-pointer relative">
                            <input
                                type="checkbox"
                                className="peer h-[1.4rem] aspect-square cursor-pointer transition-all appearance-none rounded-md border checked:bg-ufoGreen checked:border-ufoGreen"
                            />
                            <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3.5 w-3.5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    stroke="currentColor"
                                    stroke-width="1"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </span>
                        </label>
                        <div className="block text-black text-md text-center">
                            Show low inventory items only (that has reached reorder
                            level)
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <label className="inline-flex items-center align-sub justify-end cursor-default relative">
                            <input
                                type="checkbox"
                                className="peer h-[1.4rem] aspect-square cursor-pointer transition-all appearance-none rounded-md border checked:bg-ufoGreen checked:border-ufoGreen"
                                disabled
                            />
                            <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3.5 w-3.5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    stroke="currentColor"
                                    stroke-width="1"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </span>
                        </label>
                        <div className="block text-lightBlue text-md text-center cursor-default">
                            Exclude items with 0 (zero) for re-order level.
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="w-full scrollbar overflow-y-scroll">
        <Row className="mb-4">
          <Col span={8}>
            <CustomDateRange
              value={dates}
              handleChange={handleChange}
              format="YYYY-MM-DD"
            />
          </Col>
          <Col span={4}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              options={dropDownPayload(productTypes)}
              placeholder={"Select Product type"}
              onChange={(options) => onChangeProductType(options)}
            />
          </Col>
          <Col span={4}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              options={dropDownPayload(brands)}
              placeholder={"Select Brands"}
              onChange={(options) => onChangeBrand(options)}
            />
          </Col>
          <Col span={4}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              options={dropDownPayload(tags)}
              placeholder={"Select Tags"}
              onChange={(options) => onChangeTags(options)}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col span={4}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              options={dropDownPayload(season)}
              placeholder={"Select Season"}
              onChange={(options) => onChangeSeason(options)}
            />
          </Col>
          <Col span={4}>
            <Select
              style={{ width: "100%" }}
              options={[
                {
                  label: "NO_VARIANT",
                  value: "NO_VARIANT",
                },
                {
                  label: "PRODUCT_WITH_VARIANT",
                  value: "PRODUCT_WITH_VARIANT",
                },
                {
                  label: "COMPOSITE_PRODUCT",
                  value: "COMPOSITE_PRODUCT",
                },
              ]}
              placeholder={"Select Product Type"}
              onChange={(options) => onChangeType(options, "productType")}
            />
          </Col>
          <Col span={4}>
            <Select
              style={{ width: "100%" }}
              options={[
                {
                  label: "ALL",
                  value: "ALL",
                },
                {
                  label: "LOW_INVENTORY",
                  value: "LOW_INVENTORY",
                },
                {
                  label: "ZERO_ITEMS",
                  value: "ZERO_ITEMS",
                },
                {
                  label: "GREATER_ZERO_ITEMS",
                  value: "GREATER_ZERO_ITEMS",
                },
              ]}
              placeholder={"Select Inventory Type"}
              onChange={(options) => onChangeType(options, "inventoryType")}
            />
          </Col>
        </Row>
        {/* <RegisterTable
                    columns={columns}
                    // endPoint={`report/inventory-ledger`}
                    search={searchText}
                    filters={filterValue}
                    showActions={false}
                /> */}
        <Collapse>
          {Object.keys(ledgerData)?.map((k, i) => {
            return (
              <Panel header={k} key={i}>
                <Table dataSource={ledgerData[k]} columns={columns} />
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </>
  );
}

export default InventoryAdjustment;
