import { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";
import RegisterTable from "../../table/reportingTable";
import CustomDateRange from "../../../shared/customDateRange";
import { dropDownPayload } from "../../../../utils/helper";
import moment from "moment";

function PaymentByTypes({ outlet }) {
  const [searchText, setSearchText] = useState("");
  const [dates, setDates] = useState(null);
  const [filterValue, setFilterValue] = useState({
    startDate: "",
    endDate: "",
    outletIds: "",
  });
  const columns = [
    {
      title: "Type",
      render: (res) => {
        return `${res.type}`;
      },
    },
    {
      title: "Total Collected",
      dataIndex: "totalSale",
      key: "totalSale",
      render: (totalSale) => {
        return Number(totalSale).toFixed(2);
      },
    },
    {
      title: "Refund",
      dataIndex: "refund",
      key: "refund",
      render: (refund) => {
        return Number(refund).toFixed(2);
      },
    },
    {
      title: "Net",
      dataIndex: "net",
      key: "net",
      render: (net) => {
        return Number(net).toFixed(2);
      },
    },
  ];
  const handleChange = (value) => {
    setDates(value);
    if (value) {
      const startDate = value[0].toString();
      const endDate = value[1].toString();
      setFilterValue((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
      }));
    }
  };

  const onChangeOutlet = (options) => {
    setFilterValue((prevState) => ({
      ...prevState,
      outletIds: options.map((outlet) => outlet),
    }));
  };

  useEffect(() => {
    setFilterValue((prevState) => ({
      ...prevState,
    }));
  }, []);
  return (
    <>
      <div className="flex flex-col justify-between">
        <Row className="mb-4">
          <Col span={8}>
            <CustomDateRange
              value={dates}
              handleChange={handleChange}
              format="YYYY-MM-DD"
            />
          </Col>
          <Col span={1}></Col>
          <Col span={8}>
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              options={dropDownPayload(outlet)}
              placeholder={"Select Outlet"}
              onChange={onChangeOutlet}
            />
          </Col>
        </Row>
        <RegisterTable
          columns={columns}
          endPoint={`report/payment-by-types`}
          search={searchText}
          filters={filterValue}
          showActions={false}
        />
      </div>
    </>
  );
}
export default PaymentByTypes;
