import { Col, Row, Select } from "antd";
import CustomDateRange from "../../../shared/customDateRange";
import { useEffect, useState } from "react";
import { getAllSalesReportingDetails } from "../../../../services/reporting";
import { dropDownPayload } from "../../../../utils/helper";

function PaymentSummaryReport({ outlet }) {
  const [dates, setDates] = useState(null);
  const [filterValue, setFilterValue] = useState({
    startDate: "",
    endDate: "",
    outletIds: "",
  });
  const [headerCard, setHeaderCard] = useState({
    sales: 0,
    refund: 0,
    tax: 0,
    discount: 0,
    surcharge: 0,
    cogs: 0,
    salesDate: "",
    grossProfit: 0,
    margin: 0,
    avgSales: 0,
  });

  const getAllCardDetails = async () => {
    try {
      const response = await getAllSalesReportingDetails(filterValue);
      setHeaderCard(response.data?.data?.summary);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCardDetails();
  }, [filterValue]);

  const handleChange = (value) => {
    setDates(value);
    if (value) {
      const startDate = value[0].toString();
      const endDate = value[1].toString();
      setFilterValue((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
      }));
    }
  };

  const onChangeOutlet = (options) => {
    setFilterValue((prevState) => ({
      ...prevState,
      outletIds: options.map((outlet) => outlet),
    }));
  };
  useEffect(() => {
    setFilterValue((prevState) => ({
      ...prevState,
    }));
  }, []);

  return (
    <>
      <Row>
        <Col span={8}>
          <CustomDateRange
            value={dates}
            handleChange={handleChange}
            format="YYYY-MM-DD"
          />
        </Col>
        <Col span={1}></Col>
        <Col span={8}>
          <Select
            style={{ width: "100%" }}
            mode="multiple"
            options={dropDownPayload(outlet)}
            placeholder={"Select Outlet"}
            onChange={onChangeOutlet}
          />
        </Col>
      </Row>
      <div className="flex sm:gap-3 xxl:gap-6 w-full">
        <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
          <div className="lg:text-2xl xxl:text-3xl font-medium text-amber-400">
            {Number(headerCard?.totalSales || 0).toFixed(2)}
          </div>
          <div className="flex items-center justify-start mb-5 gap-1">
            <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Sales (inc. tax)
            </div>
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#a1a1a1"
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
            </svg>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Change
            </span>
            <span className="text-[0.8rem] xxl:txt-md font-semibold text-neutral-500">
              0.00%
            </span>
          </div>
        </div>
        <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
          <div className="lg:text-2xl xxl:text-3xl font-medium text-emerald-900">
            {Number(headerCard?.totalAvgSales || 0).toFixed(2)}
          </div>
          <div className="flex items-center justify-start mb-5 gap-1">
            <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Avg Sales
            </div>
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#a1a1a1"
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
            </svg>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Change
            </span>
            <span className="text-[0.8rem] xxl:txt-md font-semibold text-neutral-500">
              0.00%
            </span>
          </div>
        </div>
        <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
          <div className="lg:text-2xl xxl:text-3xl font-medium text-cyan-400">
            {Number(headerCard?.totalDiscount || 0).toFixed(2)}
          </div>
          <div className="flex items-center justify-start mb-5 gap-1">
            <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Discount
            </div>
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#a1a1a1"
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
            </svg>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Change
            </span>
            <span className="text-[0.8rem] xxl:txt-md font-semibold text-neutral-500">
              0.00%
            </span>
          </div>
        </div>
        <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
          <div className="lg:text-2xl xxl:text-3xl font-medium text-fuchsia-600">
            {Number(headerCard?.totalRefund || 0).toFixed(2)}
          </div>
          <div className="flex items-center justify-start mb-5 gap-1">
            <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Refund
            </div>
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#a1a1a1"
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
            </svg>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Change
            </span>
            <span className="text-[0.8rem] xxl:txt-md font-semibold text-neutral-500">
              0.00%
            </span>
          </div>
        </div>
        <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
          <div className="lg:text-2xl xxl:text-3xl font-medium text-rose-600">
            {Number(headerCard?.totalSalesExTax || 0).toFixed(2)}
          </div>
          <div className="flex items-center justify-start mb-5 gap-1">
            <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Sales (Ex. tax)
            </div>
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#a1a1a1"
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
            </svg>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Change
            </span>
            <span className="text-[0.8rem] xxl:txt-md font-semibold text-neutral-500">
              0.00%
            </span>
          </div>
        </div>
      </div>

      <div className="flex sm:gap-3 xxl:gap-6 w-full">
        <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
          <div className="lg:text-2xl xxl:text-3xl font-medium text-amber-400">
            {Number(headerCard?.totalCogs || 0).toFixed(2)}
          </div>
          <div className="flex items-center justify-start gap-1">
            <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Cogs
            </div>
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#a1a1a1"
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
            </svg>
          </div>
        </div>
        <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
          <div className="lg:text-2xl xxl:text-3xl font-medium text-emerald-900">
            {Number(headerCard?.totalGrossProfit || 0).toFixed(2)}
          </div>
          <div className="flex items-center justify-start gap-1">
            <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Gross Profit
            </div>
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#a1a1a1"
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
            </svg>
          </div>
        </div>
        <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
          <div className="lg:text-2xl xxl:text-3xl font-medium text-cyan-400">
            {Number(headerCard?.totalMargin || 0).toFixed(2)}
          </div>
          <div className="flex items-center justify-start gap-1">
            <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Margin
            </div>
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#a1a1a1"
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
            </svg>
          </div>
        </div>
        <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
          <div className="lg:text-2xl xxl:text-3xl font-medium text-fuchsia-600">
            {Number(headerCard?.totalSurcharge || 0).toFixed(2)}
          </div>
          <div className="flex items-center justify-start gap-1">
            <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Surcharge
            </div>
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#a1a1a1"
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
            </svg>
          </div>
        </div>
        <div className="h-auto bg-neutral-100 p-4 my-3 text-left flex-1">
          <div className="lg:text-2xl xxl:text-3xl font-medium text-rose-600">
            {Number(headerCard?.totalTax || 0).toFixed(2)}
          </div>
          <div className="flex items-center justify-start gap-1">
            <div className="text-[0.8rem] xxl:text-md uppercase font-semibold text-neutral-500">
              Total Tax
            </div>
            <svg
              className="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#a1a1a1"
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentSummaryReport;
