import PrivateLayout from "../../component/layout/privateLayout";
import Dashboard from "../../pages/dashboard";
import SettingPage from "../../pages/settingPage";
import Settings from "../../pages/settings";
import Products from "../../pages/products/product";
import AddProduct from "../../pages/products/product/addProduct";
import ProductType from "../../pages/products/product/productType";
import Brands from "../../pages/products/product/brands";
import Tags from "../../pages/products/product/tags";
import Seasons from "../../pages/products/product/seasons";
import Tier from "../../pages/customer/tiers";
import Tag from "../../pages/customer/tag";
import Customer from "../../pages/customer";
import Tax from "../../pages/products/product/tax";
import Unit from "../../pages/products/product/unit";
import Role from "../../pages/user/role";
import User from "../../pages/user";
import POS from "../../pages/pos/saleHistory";
import CashRegister from "../../pages/pos/cashRegister";
import ProcessSales from "../../pages/pos/processSales";
import Outlet from "../../pages/outlet";
import Store from "../../pages/outlet/store";
import Loyality from "../../pages/outlet/loyality";
import Register from "../../pages/outlet/register";
import Registerlisting from "../../pages/pos/cashRegister/listing";
import Purchase from "../../pages/purchase";
import AddPurchase from "../../pages/purchase/addPurchase";
import InventoryTransfer from "../../pages/inventory/inventoryTransfer";
import AddInventory from "../../pages/inventory/inventoryTransfer/addInventoryTransfer";
import Packages from "../../pages/inventory/inventoryTransfer/packages";
import Tenant from "../../pages/user/tenant";
import MyAccount from "../../pages/user/my-account";
import ProductOpeningBalance from "../../pages/inventory/opening-balance";
import InventoryAddSet from "../../pages/inventory/inventoryAddSet";
import SalesReporting from "../../pages/reporting/sales";
import InventoryReporting from "../../pages/reporting/inventory";
import RegisterReporting from "../../pages/reporting/register";
import PaymentReporting from "../../pages/reporting/payment";
import CustomReporting from "../../pages/reporting/customReport";
import CustomerReporting from "../../pages/reporting/customer";
import DailyReporting from "../../pages/reporting/dailyReporting";
import UserReporting from "../../pages/reporting/user";
import NotFound from "../../component/shared/notFound";

export const PrivateRoutes = [
  {
    path: "/",
    element: <PrivateLayout />,
    children: [
      {
        path: "*",
        element: <NotFound />,
        permission: ["Poxfy User", "Tenant User", "System User"],
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        permission: ["Poxfy User", "Tenant User", "System User"],
      },
      {
        path: "/settings",
        element: <Settings />,
        permission: ["Poxfy User", "System User"],
      },
      {
        path: "/page",
        element: <SettingPage />,
        permission: ["Poxfy User", "System User"],
      },
      {
        path: "/products",
        element: <Products />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/products/add-product",
        element: <AddProduct />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/products/product-types",
        element: <ProductType />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/products/brands",
        element: <Brands type="brand" heading={"Brand"} />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/products/tags",
        element: <Tags type="tag" heading={"Tags"} />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/products/tax",
        element: <Tax type="tax" heading={"Tax"} />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/products/unit",
        element: <Unit type="unit-type" heading={"Unit Type"} />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/products/seasons",
        element: <Seasons type="season" heading={"Season"} />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/customer",
        element: <Customer />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/customer/tier",
        element: <Tier type="customer-tier" heading={"Tier"} />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/customer/tag",
        element: <Tag type="customer-tag" heading={"Tag"} />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/user/role",
        element: <Role />,
        permission: ["Tenant User", "Poxfy User", "System User"],
      },
      {
        path: "/user",
        element: <User />,
        permission: ["Tenant User", "Poxfy User", "System User"],
      },
      {
        path: "/pos/process-sales",
        element: <ProcessSales />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/pos/sale-history",
        element: <POS />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/pos/cash-register",
        element: <Registerlisting />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/pos/cash-register-record",
        element: <CashRegister />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/outlet",
        element: <Outlet />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/store",
        element: <Store />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/loyality-point",
        element: <Loyality />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/cash-register-outlet",
        element: <Register />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/purchase",
        element: <Purchase />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/purchase/add-purchase",
        element: <AddPurchase />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/inventory/inventor-transfer",
        element: <InventoryTransfer />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/inventory/inventor-transfer/add",
        element: <AddInventory />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/inventory/packages",
        element: <Packages />,
        permission: ["Poxfy User", "System User"],
      },
      {
        path: "/user/tenant",
        element: <Tenant />,
        permission: ["Poxfy User", "System User"],
      },
      {
        path: "/user/my-account",
        element: <MyAccount />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/products/opening-balance",
        element: <ProductOpeningBalance />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/inventory-add-set",
        element: <InventoryAddSet />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/reporting/sales",
        element: <SalesReporting />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/reporting/inventory",
        element: <InventoryReporting />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/reporting/register",
        element: <RegisterReporting />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/reporting/payment",
        element: <PaymentReporting />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/reporting/customer",
        element: <CustomerReporting />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/reporting/custom-report",
        element: <CustomReporting />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/reporting/daily-report",
        element: <DailyReporting />,
        permission: ["Tenant User", "System User"],
      },
      {
        path: "/reporting/users-report",
        element: <UserReporting />,
        permission: ["Tenant User", "System User"],
      },
    ],
  },
];
