import { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";
import RegisterTable from "../../table/reportingTable";
import CustomDateRange from "../../../shared/customDateRange";
import CustomSelect from "../../../shared/customSelect/indes";
import { dropDownPayload } from "../../../../utils/helper";

function InventoryPurchase({ outlet }) {
  const [searchText, setSearchText] = useState("");
  const [dates, setDates] = useState(null);
  const [filterValue, setFilterValue] = useState({
    startDate: "",
    endDate: "",
    outletIds: "",
  });
  const columns = [
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
    },
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (cost) => {
        return Number(cost).toFixed(2);
      },
    },
  ];
  const handleChange = (value) => {
    setDates(value);
    if (value) {
      const startDate = value[0].toString();
      const endDate = value[1].toString();
      setFilterValue((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
      }));
    }
  };

  const onChangeOutlet = (options) => {
    setFilterValue((prevState) => ({
      ...prevState,
      outletIds: options.map((outlet) => outlet), // Extract IDs from selected outlets
    }));
  };

  useEffect(() => {
    setFilterValue((prevState) => ({
      ...prevState,
      // outletIds: selectedOutlet?._id,
    }));
  }, []);
  return (
    <>
      <div className="flex flex-col justify-between">
        {/* <div className="flex items-center gap-6 mb-4">
                    <form action="" className="relative w-2/6">
                        <input
                            type="text"
                            placeholder="Find ..."
                            onChange={(e) => setSearchText(e.target.value)}
                            className="block leading-none py-2 xxl:py-3 pr-6 xxl:pr-10 pl-12 xxl:pl-12 border w-full rounded-full"
                        />
                        <div className="absolute top-2 xxl:top-2 left-4 xxl:left-4 w-6 h-6">
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19.7555 18.6065L16.3182 15.2458L16.2376 15.1233C16.0878 14.9742 15.883 14.8902 15.6692 14.8902C15.4554 14.8902 15.2505 14.9742 15.1007 15.1233C12.1795 17.8033 7.67815 17.949 4.58201 15.4637C1.48586 12.9784 0.755668 8.63337 2.87568 5.31017C4.9957 1.98697 9.30807 0.716847 12.9528 2.34214C16.5976 3.96743 18.4438 7.98379 17.267 11.7276C17.1823 11.9981 17.2515 12.2922 17.4487 12.4992C17.6459 12.7062 17.9411 12.7946 18.223 12.7311C18.505 12.6676 18.7309 12.4619 18.8156 12.1914C20.2224 7.74864 18.0977 2.96755 13.8161 0.941058C9.53449 -1.08544 4.38084 0.250824 1.68905 4.08542C-1.00273 7.92001 -0.424821 13.1021 3.04893 16.2795C6.52268 19.4569 11.8498 19.6759 15.5841 16.7949L18.6277 19.7705C18.942 20.0765 19.4502 20.0765 19.7645 19.7705C20.0785 19.4602 20.0785 18.9606 19.7645 18.6503L19.7555 18.6065Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                    </form>

                </div> */}
        <Row className="mb-4">
          <Col span={8}>
            <CustomDateRange
              value={dates}
              handleChange={handleChange}
              format="YYYY-MM-DD"
            />
          </Col>
          <Col span={1}></Col>
          <Col span={8}>
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              options={dropDownPayload(outlet)}
              placeholder={"Select Outlet"}
              onChange={onChangeOutlet}
            />
          </Col>
        </Row>
        <RegisterTable
          columns={columns}
          endPoint={`report/inventory-purchase`}
          search={searchText}
          filters={filterValue}
          showActions={false}
        />
      </div>
    </>
  );
}
export default InventoryPurchase;
