import { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";
import RegisterTable from "../../table/reportingTable";
import CustomDateRange from "../../../shared/customDateRange";
import CustomSelect from "../../../shared/customSelect/indes";
import { dropDownPayload } from "../../../../utils/helper";

function SalesByUser({ outlet }) {
  const [searchText, setSearchText] = useState("");
  const [dates, setDates] = useState(null);
  const [filterValue, setFilterValue] = useState({
    startDate: "",
    endDate: "",
    outletIds: "",
  });
  const columns = [
    {
      title: "User",
      render: (res) => {
        return `${res.createdBy?.fullName}`;
      },
    },
    {
      title: "Sales (Incl.Tax)",
      dataIndex: "salesIncludingTax",
      key: "salesIncludingTax",
      render: (salesIncludingTax) => {
        return Number(salesIncludingTax).toFixed(2);
      },
    },
    {
      title: "Sales (Excl.Tax)",
      dataIndex: "salesExcludingTax",
      key: "salesExcludingTax",
      render: (salesExcludingTax) => {
        return Number(salesExcludingTax).toFixed(2);
      },
    },
    {
      title: "Refund",
      dataIndex: "refund",
      key: "refund",
      render: (refund) => {
        return Number(refund).toFixed(2);
      },
    },
    {
      title: "Discount",
      dataIndex: "orderDiscount",
      key: "orderDiscount",
      render: (orderDiscount) => {
        return Number(orderDiscount).toFixed(2);
      },
    },
  ];
  const handleChange = (value) => {
    setDates(value);
    if (value) {
      const startDate = value[0].toString();
      const endDate = value[1].toString();
      setFilterValue((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
      }));
    }
  };

  const onChangeOutlet = (options) => {
    setFilterValue((prevState) => ({
      ...prevState,
      outletIds: options.map((outlet) => outlet), // Extract IDs from selected outlets
    }));
  };

  useEffect(() => {
    setFilterValue((prevState) => ({
      ...prevState,
    }));
  }, []);
  return (
    <>
      <div className="flex flex-col justify-between">
        <Row className="mb-4">
          <Col span={8}>
            <CustomDateRange
              value={dates}
              handleChange={handleChange}
              format="YYYY-MM-DD"
            />
          </Col>
          <Col span={1}></Col>
          <Col span={8}>
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              options={dropDownPayload(outlet)}
              placeholder={"Select Outlet"}
              onChange={onChangeOutlet}
            />
          </Col>
        </Row>
        <RegisterTable
          columns={columns}
          endPoint={`report/sales-by-user`}
          search={searchText}
          filters={filterValue}
          showActions={false}
        />
      </div>
    </>
  );
}
export default SalesByUser;
