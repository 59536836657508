import { DatePicker } from "antd";
import { predefinedRanges } from "../../../utils/helper";

function CustomDateRange({ dates, handleChange, format }) {
  const { RangePicker } = DatePicker;

  return (
    <RangePicker
      value={dates}
      onChange={(values) => handleChange(values)}
      ranges={predefinedRanges} // Predefined options
      // showTime
      format={format}
    />
  );
}
export default CustomDateRange;
