import { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";
import RegisterTable from "../../table/reportingTable";
import CustomDateRange from "../../../shared/customDateRange";
import { dropDownPayload } from "../../../../utils/helper";
import moment from "moment";

function TransactionReport({ outlet, cashRegister }) {
  const [searchText, setSearchText] = useState("");
  const [dates, setDates] = useState(null);
  const [filterValue, setFilterValue] = useState({
    startDate: "",
    endDate: "",
    outletIds: "",
  });
  const columns = [
    {
      title: "Register (Outlet)",
      render: (res) => {
        return `${res.registerName} (${res.outletName})`;
      },
    },
    {
      title: "Date",
      render: (_, row) => (
        <p>{moment(row?.transactionDate).format("MMMM Do YYYY hh:mm:ss")}</p>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return Number(amount).toFixed(2);
      },
    },
    {
      title: "User",
      render: (res) => {
        return res?.createdBy?.fullName || "N/A";
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
  ];
  const handleChange = (value) => {
    setDates(value);
    if (value) {
      const startDate = value[0].toString();
      const endDate = value[1].toString();
      setFilterValue((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
      }));
    }
  };

  const onChangeOutlet = (options) => {
    setFilterValue((prevState) => ({
      ...prevState,
      outletIds: options.map((outlet) => outlet), // Extract IDs from selected outlets
    }));
  };

  const onChangeRegister = (options) => {
    setFilterValue((prevState) => ({
      ...prevState,
      cashRegisterIds: options.map((outlet) => outlet), // Extract IDs from selected outlets
    }));
  };

  useEffect(() => {
    setFilterValue((prevState) => ({
      ...prevState,
    }));
  }, []);
  return (
    <>
      <div className="flex flex-col justify-between">
        <Row className="mb-4">
          <Col span={8}>
            <CustomDateRange
              value={dates}
              handleChange={handleChange}
              format="YYYY-MM-DD"
            />
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              options={dropDownPayload(outlet)}
              placeholder={"Select Outlet"}
              onChange={onChangeOutlet}
            />
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              options={dropDownPayload(cashRegister)}
              placeholder={"Select Register"}
              onChange={onChangeRegister}
            />
          </Col>
        </Row>
        <RegisterTable
          columns={columns}
          endPoint={`report/cash-register-transactions`}
          search={searchText}
          filters={filterValue}
          showActions={false}
        />
      </div>
    </>
  );
}
export default TransactionReport;
