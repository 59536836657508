import React, { useState } from "react";
import { useEffect } from "react";

import ShiftReport from "../../../component/reporting/register/shift-report";
import EmptyShiftReport from "../../../component/reporting/register/empty-register";
import TransactionReport from "../../../component/reporting/register/transaction-report";
import { getAllOutletApi, getAllRegisterApi } from "../../../services/outlet";
import { Tabs } from "antd";
import { getUserPermissions } from "../../../redux/feature/authSlice";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

function RegisterReporting() {
  const allowedPermissionFromStore = useSelector(getUserPermissions) || {};
  const [outlet, setOutlet] = useState([]);
  const [cashRegister, setCashRegister] = useState([]);
  const [tabWithPermission, setTabWithPermission] = useState([]);
  useEffect(() => {
    if (allowedPermissionFromStore.masterUser == true) {
      setTabWithPermission(tabData);
    } else {
      const userPermissions = new Set(
        allowedPermissionFromStore?.permissions.map((p) => p.key),
      );
      const filteredTabs = tabData.filter((tab) =>
        userPermissions.has(tab.permission),
      );

      setTabWithPermission(filteredTabs);
    }
  }, []);

  const tabData = [
    {
      key: "1",
      label: "Shift Report",
      children: <ShiftReport outlet={outlet} cashRegister={cashRegister} />,
      permission: "GetCashRegisterShiftReportWithoutSale",
    },
    {
      key: "2",
      label: "Drawer Opened without Sale",
      children: (
        <EmptyShiftReport outlet={outlet} cashRegister={cashRegister} />
      ),
      permission: "GetCashRegisterShiftReportWithoutSale",
    },
    {
      key: "4",
      label: "Float In/Out",
      children: (
        <TransactionReport outlet={outlet} cashRegister={cashRegister} />
      ),
      permission: "GetCashRegisterShiftReportWithoutSale",
    },
  ];

  const getOutlet = async () => {
    try {
      const { data: response } = await getAllOutletApi();
      const { data: record } = response;
      setOutlet(record?.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getCashRegisters = async () => {
    try {
      const { data: response } = await getAllRegisterApi();
      const { data: record } = response;
      setCashRegister(record?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getOutlet();
  }, []);

  useEffect(() => {
    getCashRegisters();
  }, []);

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:px-3 xxl:px-6 sm:py-4 xxl:py-8 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8">
          <h2 className="sm:text-xl xxl:text-2xl font-medium">Register</h2>
        </div>

        <div className="overflow-auto scrollbar">
          <Tabs
            defaultActiveKey="1"
            className="filtersTabMain"
            items={tabWithPermission}
          />
        </div>
      </div>
    </main>
  );
}

export default RegisterReporting;
