import React, { useState } from "react";
import { Form, Input, Button, Upload, message, InputNumber } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import CustomSelect from "../shared/customSelect/indes";
import { dropDownPayload } from "../../utils/helper";
import ActionAccessWrapper from "../shared/actionAccessWrapper";

function EditStore({
  form,
  onFinish,
  language,
  currency,
  uploadedUrl,
  setUploadedUrl,
}) {
  const [loading, setLoading] = useState(false);

  const props = {
    name: "file",
    action: `${process.env.REACT_APP_API_BASE_URL}/file/multi/productType`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        form.setFieldsValue({
          logo: info.file.response.data.file,
          skuConfig: {
            type: 1,
            prefix: "SR",
            suffix: ".",
          },
        });
        setUploadedUrl(info.file.response.data.file);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const selectOptions = (options) =>
    options.map((item) => ({
      value: item._id,
      label: item.value,
    }));

  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="flex flex-wrap gap-4">
          <div className="w-[calc(50%_-_1rem)]">
            <Form.Item
              name="facebook"
              className="mb-0"
              label={
                <span className="text-lightGrey font-medium">
                  Facebook Link
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                placeholder="Enter Link"
              />
            </Form.Item>
          </div>
          <div className="w-[calc(50%_-_1rem)]">
            <Form.Item
              name="linkedIn"
              className="mb-0"
              label={
                <span className="text-lightGrey font-medium">
                  LinkedIn Link
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                placeholder="linkedIn Link"
              />
            </Form.Item>
          </div>
          <div className="w-[calc(50%_-_1rem)]">
            <Form.Item
              name="twitter"
              className="mb-0"
              label={
                <span className="text-lightGrey font-medium">Twitter Link</span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                placeholder="twitter Link"
              />
            </Form.Item>
          </div>
          <div className="w-[calc(50%_-_1rem)]">
            <Form.Item
              name="website"
              className="mb-0"
              label={
                <span className="text-lightGrey font-medium">Website Link</span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                placeholder="website Link"
              />
            </Form.Item>
          </div>
          <div className="w-[calc(50%_-_1rem)]">
            <Form.Item
              name="languageId"
              className="mb-0"
              label={
                <span className="text-lightGrey font-medium">Language </span>
              }
            >
              <CustomSelect options={dropDownPayload(language)} />
            </Form.Item>
          </div>
          <div className="w-[calc(50%_-_1rem)]">
            <Form.Item
              name="currencyId"
              className="mb-0"
              label={
                <span className="text-lightGrey font-medium">Currency </span>
              }
            >
              <CustomSelect options={selectOptions(currency)} />
            </Form.Item>
          </div>

          <div className="w-[calc(100%_-_1rem)]">
            <h4 className="text-left font-bold mt-5">SKU Configuration</h4>
          </div>
          <div className="w-[calc(33%_-_1rem)]">
            <Form.Item
              name={["skuConfig", "type"]}
              label={<span className="text-lightGrey font-medium">SKU</span>}
              rules={[{ required: true, message: "Type is required" }]}
            >
              <InputNumber
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-1 xxl:py-2 sm:px-4 xxl:px-6"
                placeholder="Enter type"
                min={0}
              />
            </Form.Item>
          </div>

          <div className="w-[calc(33%_-_1rem)]">
            <Form.Item
              name={["skuConfig", "prefix"]}
              label={<span className="text-lightGrey font-medium">Prefix</span>}
              rules={[{ required: true, message: "Prefix is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                placeholder="Enter prefix"
              />
            </Form.Item>
          </div>

          <div className="w-[calc(33%_-_1rem)]">
            <Form.Item
              name={["skuConfig", "suffix"]}
              label={<span className="text-lightGrey font-medium">Suffix</span>}
              rules={[{ required: true, message: "Suffix is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full sm:py-2 xxl:py-3 sm:px-4 xxl:px-6"
                placeholder="Enter suffix"
              />
            </Form.Item>
          </div>

          <div className="w-full">
            <Form.Item name="logo" label="Logo">
              <Upload
                name="logo"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                {...props}
                // beforeUpload={beforeUpload}
                // onChange={handleChange}
              >
                {uploadedUrl ? (
                  <img
                    src={uploadedUrl}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </div>
          <ActionAccessWrapper permissionKeys={"UpdateUserStoreById"}>
            <div className="flex items-center justify-end gap-3 w-full">
              <Button
                type="primary"
                htmlType="submit"
                className="h-auto cursor-pointer ml-auto leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium"
              >
                Save
              </Button>
            </div>
          </ActionAccessWrapper>
        </div>
      </Form>
    </>
  );
}

export default EditStore;
