import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row, Select } from "antd";
import { getAllSalesReportingDetails } from "../../../services/reporting";
import { getAllOutletApi } from "../../../services/outlet";
import { dropDownPayload } from "../../../utils/helper";
import CustomDateRange from "../../../component/shared/customDateRange";
import PaymentByTypes from "../../../component/reporting/payment/payment-by-type";
import SalesInstruction from "../../../component/reporting/sales/salesInstruction";
import TaxCollectionReport from "../../../component/reporting/payment/tax-report";

import { Tabs } from "antd";
import PaymentSummaryReport from "../../../component/reporting/payment/paymentSummary";
import { getUserPermissions } from "../../../redux/feature/authSlice";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

function PaymentReporting() {
  const [outlet, setOutlet] = useState([]);
  const allowedPermissionFromStore = useSelector(getUserPermissions) || {};
  const [tabWithPermission, setTabWithPermission] = useState([]);

  const getOutlet = async () => {
    try {
      const { data: response } = await getAllOutletApi();
      const { data: record } = response;
      setOutlet(record?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getOutlet();
  }, []);

  const tabData = [
    {
      key: "1",
      label: "Payments Summary",
      children: <PaymentSummaryReport outlet={outlet} />,
      permission: "GetPaymentSummary",
    },
    {
      key: "2",
      label: "Types",
      children: <PaymentByTypes outlet={outlet} />,
      permission: "GetPaymentByType",
    },
    {
      key: "3",
      label: "Payment Transactions",
      children: <SalesInstruction />,
      permission: "GetPaymentTransactions",
    },
    {
      key: "4",
      label: "Sales Tax Collected",
      children: <TaxCollectionReport outlet={outlet} />,
      permission: "GetTaxCollectionReport",
    },
  ];

  useEffect(() => {
    if (allowedPermissionFromStore.masterUser == true) {
      setTabWithPermission(tabData);
    } else {
      const userPermissions = new Set(
        allowedPermissionFromStore?.permissions.map((p) => p.key),
      );
      const filteredTabs = tabData.filter((tab) =>
        userPermissions.has(tab.permission),
      );

      setTabWithPermission(filteredTabs);
    }
  }, []);

  return (
    <main className="sm:mt-3 xxl:mt-6 flex-1 overflow-hidden">
      <div className="sm:px-3 xxl:px-6 sm:py-4 xxl:py-8 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between sm:mb-4 xxl:mb-8">
          <h2 className="sm:text-xl xxl:text-2xl font-medium">Payments</h2>
        </div>

        <div className="overflow-auto scrollbar">
          <Tabs
            defaultActiveKey="1"
            className="filtersTabMain"
            items={tabWithPermission}
          />
        </div>
      </div>
    </main>
  );
}

export default PaymentReporting;
