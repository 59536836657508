import Api from "../../network/client";

export const getAll = async (endPoint, params) => {
  return Api.get(endPoint, { params });
};

export const getSlugListOfValuesByKey = async (key) => {
  return Api.get(`product-slug/${key}`);
};

export const getAllListOfValueByKey = async (key) => {
  return Api.get(`list-of-values?type=${key}`);
};

export const getAllLanguages = async () => {
  return Api.get(`list-of-values/languages`);
};

export const updateLov = async (id, payload) => {
  return Api.put(`list-of-values/cms/${id}`, payload);
};
