import { Col, Select, Row } from "antd";
import { useState } from "react";
import CustomDateRange from "../../../shared/customDateRange";
import RegisterTable from "../../table/reportingTable";
import { dropDownPayload } from "../../../../utils/helper";
import CustomSelect from "../../../shared/customSelect/indes";

function CustomerTier({ outlet }) {
  const [filterValue, setFilterValue] = useState({
    type: "tier",
    startDate: "",
    endDate: "",
  });
  const [dates, setDates] = useState(null);
  const [searchText, setSearchText] = useState("");
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Order Discount",
      dataIndex: "orderDiscount",
      key: "orderDiscount",
      render: (orderDiscount) => {
        return Number(orderDiscount).toFixed(2);
      },
    },
    {
      title: "Refund",
      dataIndex: "refund",
      key: "refund",
      render: (refund) => {
        return Number(refund).toFixed(2);
      },
    },
    {
      title: "Sales Excluding Tax",
      dataIndex: "salesExcludingTax",
      key: "salesExcludingTax",
      render: (salesExcludingTax) => {
        return Number(salesExcludingTax).toFixed(2);
      },
    },
    {
      title: "Sales Excluding Tax",
      dataIndex: "salesExcludingTax",
      key: "salesExcludingTax",
      render: (salesExcludingTax) => {
        return Number(salesExcludingTax).toFixed(2);
      },
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (tax) => {
        return Number(tax).toFixed(2);
      },
    },
  ];
  const onChangeOutlet = (options) => {
    setFilterValue((prevState) => ({
      ...prevState,
      outletIds: options.map((outlet) => outlet), // Extract IDs from selected outlets
    }));
  };
  const handleChange = (value) => {
    setDates(value);
    if (value) {
      const startDate = value[0].toString();
      const endDate = value[1].toString();
      setFilterValue((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
      }));
    }
  };
  return (
    <div className="flex flex-col justify-between">
      <Row>
        <Col span={8}>
          <CustomDateRange
            value={dates}
            handleChange={handleChange}
            format="YYYY-MM-DD"
          />
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Select
            style={{ width: "100%" }}
            placeholder="Type"
            options={[
              {
                label: "Tier",
                value: "tier",
              },
              {
                label: "Tag",
                value: "tag",
              },
            ]}
            onChange={(e) => setFilterValue({ ...filterValue, type: e })}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Select
            style={{ width: "100%" }}
            mode="multiple"
            options={dropDownPayload(outlet)}
            placeholder={"Select Outlet"}
            onChange={onChangeOutlet}
          />
        </Col>
      </Row>
      <div className="flex flex-col justify-between mt-4">
        <RegisterTable
          columns={columns}
          endPoint={`report/sales-by-customer-type`}
          search={searchText}
          filters={filterValue}
          showActions={false}
        />
      </div>
    </div>
  );
}

export default CustomerTier;
