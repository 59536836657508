import { openNotification } from "../../component/shared/notification";
import { isValidPhoneNumber } from "react-phone-number-input";
import dayjs from "dayjs";
const today = dayjs(); // Get the current date

export const getAPIUrl = (endPoint, queryParams) => {
  if (!endPoint) return "";
  if (!queryParams) return endPoint;

  let queryString = "";
  for (const key in queryParams) {
    const value = queryParams[key];
    if (value) {
      queryString += `${queryString ? "&" : ""}${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }
  }

  const apiUrl = `${endPoint}?${queryString}`;
  return apiUrl;
};

export const dropDownPayload = (payload, valueKey, extraKey) => {
  if (payload?.length) {
    return payload?.map((item) => ({
      label: item?.name || item?.fullName,
      // this key is by default id but if author want any other value to be the value of `value key` now author can
      value: item?._id,
      ...item,
      // one can add extra key as per payload OR business requirements
      ...(extraKey && { extraKey: item[extraKey] }),
    }));
  } else {
    return [];
  }
};

export const dropDownPayloadCustomer = (payload, valueKey, extraKey) => {
  if (payload?.length) {
    return payload?.map((item) => ({
      label: `${item?.name || item?.fullName} (${item.phone || "0"})`,
      // this key is by default id but if author want any other value to be the value of `value key` now author can
      value: item?._id,
      ...item,
      // one can add extra key as per payload OR business requirements
      ...(extraKey && { extraKey: item[extraKey] }),
    }));
  } else {
    return [];
  }
};

export const CalculateTotalPrice = (items, discountTypes, form) => {
  let total = 0;
  items.forEach((item) => {
    item.variants.forEach((variant) => {
      let quantity = variant.quantity || 1;
      let retailPrice = variant.retailPrice || 0;
      let tax = variant.tax || 0;

      // Apply discount based on discountType
      // if (variant.discountType === "percentage") {
      //   discount = (Number(retailPrice) * Number(discount)) / 100;
      // }
      //  else if (variant.discountType === "fix_rate") {
      //   // discount remains as the fixed rate
      //   discount = (Number(retailPrice) - Number(discount));
      // }

      // Calculate total for this variant after discount
      let variantTotal = retailPrice * quantity;
      if (tax > 0) {
        variantTotal += (variantTotal * tax) / 100;
      }
      total += variantTotal;
    });
  });
  const discount = CalculateTotalDiscountOfSales(items, discountTypes, form);
  return (+total - discount).toFixed(2);
};

export const CalculateTotalPriceWithoutDiscount = (items) => {
  let total = 0;
  items.forEach((item) => {
    item.variants.forEach((variant) => {
      let discount = variant.discount || 0;
      let quantity = variant.quantity || 1;
      let retailPrice = variant.retailPrice || 0;
      let tax = variant.tax || 0;
      if (variant.discountType === "percentage") {
        discount = (Number(retailPrice) * Number(discount)) / 100;
      }
      // else if (variant.discountType === "fix_rate") {
      //   discount = Number(retailPrice) / 100;
      // }

      let variantTotal = retailPrice * quantity;
      if (tax > 0) {
        variantTotal += (variantTotal * tax) / 100;
      }
      total += variantTotal;
    });
  });
  return +total.toFixed(2);
};
export const CalculateTotalPriceWithoutDiscountAndTax = (items) => {
  let total = 0;
  items.forEach((item) => {
    item.variants.forEach((variant) => {
      let discount = variant.discount || 0;
      let quantity = variant.quantity || 1;
      let retailPrice = variant.retailPrice || 0;
      if (variant.discountType === "percentage") {
        discount = (Number(retailPrice) * Number(discount)) / 100;
      }
      // else if (variant.discountType === "fix_rate") {
      //   discount = Number(retailPrice) / 100;
      // }

      let variantTotal = retailPrice * quantity;
      total += variantTotal;
    });
  });
  return +total.toFixed(2);
};

export const CalculateTotalDiscountOfSales = (items, discountTypes, form) => {
  let total = 0;
  items.forEach((item) => {
    item.variants.forEach((variant) => {
      let quantity = variant.quantity || 1;
      let retailPrice = variant.retailPrice || 0;
      total += quantity * retailPrice;
    });
  });
  let discount = form?.getFieldsValue()?.discount;
  const discountTypeId = form?.getFieldsValue()?.discountTypeId;
  if (discountTypeId) {
    const discountType = discountTypes?.find((x) => x._id === discountTypeId);

    if (discountType?.key === "percentage") {
      discount = (total * +discount) / 100;
    }
    return discount;
  }
  return 0;
};

export const CalculateTotalDiscountOfSalesInPopup = (
  items,
  discountTypes,
  data,
) => {
  let total = 0;
  items.forEach((item) => {
    item.variants.forEach((variant) => {
      let quantity = variant.quantity || 1;
      let retailPrice = variant.retailPrice || 0;
      total += quantity * retailPrice;
    });
  });
  let discount = data?.discount;
  const discountTypeId = data?.discountTypeId;
  if (discountTypeId) {
    const discountType = discountTypes?.find((x) => x._id === discountTypeId);

    if (discountType?.key === "percentage") {
      discount = (total * +discount) / 100;
    }
    return discount;
  }
  return 0;
};

export const dropDownPayloadLov = (payload, valueKey, extraKey) => {
  if (payload?.length) {
    return payload?.map((item) => ({
      label: item.name ? item.name : item.value,
      // this key is by default id but if author want any other value to be the value of `value key` now author can
      value: item._id,
      // one can add extra key as per payload OR business requirements
      ...(extraKey && { extraKey: item[extraKey] }),
    }));
  } else {
    return [];
  }
};

export const addDayIdToSchedule = (schedule, ids) => {
  // Create a mapping of key to _id from the ids array
  const idMap = {};
  ids.forEach((item) => {
    idMap[item.key] = item.dayId; // Map the key to its corresponding _id
  });

  // Update the schedule array to include dayId
  return schedule.map((item) => {
    item.dayId = idMap[item.key] || null; // Add dayId from the map, or set to null if not found
    return item; // Return the updated item
  });
};

export function formatNames(arr) {
  return arr?.map((x) => x.toUpperCase())?.join(" / ");
}

export const dropDownPayloadForAttribute = (payload, valueKey, extraKey) => {
  if (payload?.length) {
    return payload?.map((item) => ({
      label: item.type ? item.type : item.type,
      // this key is by default id but if author want any other value to be the value of `value key` now author can
      value: item.type,
      // one can add extra key as per payload OR business requirements
      ...(extraKey && { extraKey: item[extraKey] }),
    }));
  } else {
    return [];
  }
};

export function calculateDiscountedPrice(
  retailPrice,
  discount,
  quantity,
  discountType,
) {
  let discountedPrice;
  if (discountType === "fix_rate") {
    // Fixed rate discount calculation
    discountedPrice =
      (Number(retailPrice) - Number(discount)) * Number(quantity);
  } else if (discountType === "percentage") {
    // Percentage discount calculation
    discountedPrice =
      (Number(retailPrice) - Number(retailPrice) * (Number(discount) / 100)) *
      Number(quantity);
  }

  return Number(discountedPrice).toFixed(2);
}

export const CalculateTotalDiscount = (items) => {
  let totalDiscount = 0;

  items.forEach((item) => {
    item.variants.forEach((variant) => {
      let discount = variant.discount || 0;
      let retailPrice = variant.retailPrice || 0;
      let quantity = variant.quantity || 1;
      let tax = variant.tax || 0;
      let discountAmount = 0;

      // Calculate discount amount based on discountType
      if (variant.discountType === "percentage") {
        discountAmount = (retailPrice * discount) / 100;
      } else if (variant.discountType === "fix_rate") {
        discountAmount = discount;
      }

      // if (tax > 0) {
      //   discountAmount += (discountAmount * tax) / 100;
      // }

      // Total discount for this variant (after considering quantity)
      totalDiscount += discountAmount * quantity;
    });
  });

  return Number(totalDiscount).toFixed(2);
};

export const CalculateTotalTax = (items) => {
  let totalTax = 0;

  items.forEach((item) => {
    item.variants.forEach((variant) => {
      let discount = variant.discount || 0;
      let retailPrice = variant.retailPrice || 0;
      let quantity = variant.quantity || 1;
      let tax = variant.tax || 0; // Tax in percentage
      // Calculate discount amount based on discountType
      let discountAmount = 0;
      if (variant.discountType === "percentage") {
        discountAmount = (retailPrice * discount) / 100;
      } else if (variant.discountType === "fix_rate") {
        discountAmount = discount;
      }
      // Calculate the effective price after discount
      let effectivePrice = retailPrice * quantity;
      // Calculate tax for this variant
      if (tax > 0) {
        totalTax += (effectivePrice * tax) / 100;
      }
    });
  });

  return Number(totalTax).toFixed(2);
};

export const safeNumber = (value) =>
  isNaN(Number(value)) ? 0 : Number(value).toFixed(2);

export const CalculateTotalPriceForInventory = (items) => {
  let total = 0;
  items.forEach((item) => {
    item.variants.forEach((variant) => {
      let discount = variant.discount || 0;
      let quantity = variant.quantity || 1;
      let costPrice = variant.costPrice || 0;
      let tax = variant.tax || 0;

      // Apply discount based on discountType
      if (variant.discountType === "percentage") {
        discount = (Number(costPrice) * Number(discount)) / 100;
      }
      // else if (variant.discountType === "fix_rate") {
      //   // discount remains as the fixed rate
      //   discount = (Number(costPrice) - Number(discount)) / 100;
      // }

      // Calculate total for this variant after discount
      let variantTotal = (costPrice - discount) * quantity;
      if (tax > 0) {
        variantTotal += (variantTotal * tax) / 100;
      }
      total += variantTotal;
    });
  });
  return +total.toFixed(2);
};

export const phoneNumberValidator = (t) => ({
  validator(_e, value) {
    if (value && !isValidPhoneNumber(value)) {
      return Promise.reject(new Error(t("phone_number_error_invalid")));
    }
    return Promise.resolve();
  },
});

export const predefinedRanges = {
  Today: [today.clone().startOf("day"), today.clone().endOf("day")],
  Yesterday: [
    today.clone().subtract(1, "days").startOf("day"),
    today.clone().subtract(1, "days").endOf("day"),
  ],
  "Last 7 Days": [
    today.clone().subtract(7, "days").startOf("day"),
    today.clone().endOf("day"),
  ],
  "Last 30 Days": [
    today.clone().subtract(30, "days").startOf("day"),
    today.clone().endOf("day"),
  ],
  "This Month": [today.clone().startOf("month"), today.clone().endOf("month")],
  "Last Month": [
    today.clone().subtract(1, "month").startOf("month"),
    today.clone().subtract(1, "month").endOf("month"),
  ],
};
