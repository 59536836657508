import React, { useState } from "react";

function CashInModal({ action, onFinishCashInAndOut }) {
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [errorNotes, setErrorNotes] = useState("");
  const [notes, setNotes] = useState("");
  const onClickSubmit = () => {
    if (amount.trim().length == 0) {
      setError("Amount is required");
    } else if (!notes || !notes.length) {
      setErrorNotes("Notes is required.");
    } else {
      const payload = {
        amount,
        notes,
        isActive: true,
      };
      onFinishCashInAndOut(payload, action.toLowerCase());
    }
  };
  return (
    <>
      <div className="text-left">
        <span className="text-sm text-ufoGreen">Amount</span>
        <div className="flex items-center justify-start gap-4 mt-4">
          <input
            type="text"
            className="text-sm block border border-lightGrey rounded-lg w-full leading-none py-3 px-4 outline-0"
            placeholder="00.00"
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
      </div>
      <div className="text-left mt-2 color-red text-red-600">{error}</div>
      <div className="flex items-center justify-start mt-10">
        <svg
          className="mr-2"
          width={19}
          height={18}
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.2865 1.29032H15.7692V0.703812C15.7692 0.322581 15.4468 0 15.0657 0C14.6847 0 14.3623 0.322581 14.3623 0.703812V1.29032H5.7156V0.703812C5.7156 0.322581 5.39318 0 5.01214 0C4.6311 0 4.30869 0.322581 4.30869 0.703812V1.29032H0.703459C0.322419 1.29032 0 1.6129 0 1.99413V17.7713C0 18.1525 0.322419 18.4751 0.703459 18.4751H19.2865C19.6675 18.4751 19.99 18.1525 19.99 17.7713V1.99413C19.99 1.6129 19.6675 1.29032 19.2865 1.29032ZM4.30869 2.69795V3.07918C4.30869 3.46041 4.6311 3.78299 5.01214 3.78299C5.39318 3.78299 5.7156 3.46041 5.7156 3.07918V2.69795H14.3623V3.07918C14.3623 3.46041 14.6847 3.78299 15.0657 3.78299C15.4468 3.78299 15.7692 3.46041 15.7692 3.07918V2.69795H18.5537V4.51613H1.40692V2.69795H4.30869ZM1.40692 17.0674V5.95308H18.583V17.0674H1.40692Z"
            fill="#00E37D"
          />
          <path
            d="M15.0657 9.17883H4.92416C4.54312 9.17883 4.2207 9.50141 4.2207 9.88265C4.2207 10.2639 4.54312 10.5865 4.92416 10.5865H15.095C15.476 10.5865 15.7985 10.2639 15.7985 9.88265C15.7985 9.50141 15.476 9.17883 15.0657 9.17883Z"
            fill="#00E37D"
          />
          <path
            d="M9.99493 12.61H4.92416C4.54312 12.61 4.2207 12.9326 4.2207 13.3138C4.2207 13.695 4.54312 14.0176 4.92416 14.0176H9.99493C10.376 14.0176 10.6984 13.695 10.6984 13.3138C10.6984 12.9032 10.376 12.61 9.99493 12.61Z"
            fill="#00E37D"
          />
        </svg>
        <span className="text-sm text-ufoGreen">Notes</span>
      </div>
      <div className="flex items-center justify-start gap-4 mt-4">
        <input
          type="text"
          onChange={(e) => setNotes(e.target.value)}
          className="text-sm block border border-lightGrey rounded-lg w-full leading-none py-3 px-4 outline-0"
        />
      </div>

      <div className="text-left mt-2 color-red text-red-600">{errorNotes}</div>
      <div className="flex items-center justify-end gap-3 mt-10">
        <input
          onClick={onClickSubmit}
          defaultValue="Save"
          className="cursor-pointer leading-none block py-4 px-2 bg-redOrange rounded-full text-white text-center font-medium border border-redOrange"
        />
      </div>
    </>
  );
}

export default CashInModal;
