export const Menu = [
  {
    id: 1,
    to: "./dashboard",
    label: "Dashboard",
    permission: ["Poxfy User", "Tenant User", "System User"],
    icon: (
      <span className="flex items-center justify-center w-10 rounded-full aspect-square bg-[#f2fff9] transition ease-in-out group-hover:bg-white group-hover:border-2 group-hover:border-redOrange">
        {
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21}
            height={22}
            fill="#00e37d"
            viewBox="0 0 256 256"
          >
            <path d="M219.31,108.68l-80-80a16,16,0,0,0-22.62,0l-80,80A15.87,15.87,0,0,0,32,120v96a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V160h32v56a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V120A15.87,15.87,0,0,0,219.31,108.68ZM208,208H160V152a8,8,0,0,0-8-8H104a8,8,0,0,0-8,8v56H48V120l80-80,80,80Z"></path>
          </svg>
        }
      </span>
    ),
  },
  {
    id: 11,
    to: "./pos/process-sales",
    label: "Point of Sales",
    permission: ["Tenant User", "System User"],
    icon: (
      <span class="flex items-center justify-center w-10 rounded-full aspect-square bg-[#fff1ed] transition ease-in-out group-hover:bg-white group-[.menu-item-has-children.active]:bg-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={21}
          height={22}
          fill="#fe5e5e"
          viewBox="0 0 256 256"
        >
          <path d="M136,120v56a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm36.84-.8-5.6,56A8,8,0,0,0,174.4,184a7.32,7.32,0,0,0,.81,0,8,8,0,0,0,7.95-7.2l5.6-56a8,8,0,0,0-15.92-1.6Zm-89.68,0a8,8,0,0,0-15.92,1.6l5.6,56a8,8,0,0,0,8,7.2,7.32,7.32,0,0,0,.81,0,8,8,0,0,0,7.16-8.76ZM239.93,89.06,224.86,202.12A16.06,16.06,0,0,1,209,216H47a16.06,16.06,0,0,1-15.86-13.88L16.07,89.06A8,8,0,0,1,24,80H68.37L122,18.73a8,8,0,0,1,12,0L187.63,80H232a8,8,0,0,1,7.93,9.06ZM89.63,80h76.74L128,36.15ZM222.86,96H33.14L47,200H209Z"></path>
        </svg>
      </span>
    ),
    children: [
      {
        id: 1,
        to: "./pos/process-sales",
        label: "Process Sales",
        permission: "CreateSales",
      },
      {
        id: 2,
        to: "./pos/sale-history",
        label: "Sale History",
        permission: "GetAllSales",
      },
      {
        id: 3,
        to: "./pos/cash-register",
        label: "Cash Registers",
        permission: "GetAllCashRegisters",
      },
    ],
  },
  {
    id: 2,
    to: "./settings",
    label: "Landing Page",
    permission: ["Poxfy User", "System User"],
    icon: (
      <span className="flex items-center justify-center w-10 rounded-full aspect-square bg-[#fff6da] transition ease-in-out group-hover:bg-white group-hover:border-2 group-hover:border-redOrange">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={21}
          height={22}
          fill="#ffc107"
          viewBox="0 0 256 256"
        >
          <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-42.34-61.66a8,8,0,0,1,0,11.32l-24,24a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L120,164.69V120a8,8,0,0,1,16,0v44.69l10.34-10.35A8,8,0,0,1,157.66,154.34Z"></path>
        </svg>
      </span>
    ),
  },
  {
    id: 3,
    to: "./page",
    label: "Pages",
    permission: ["Poxfy User", "System User"],
    icon: (
      <span className="flex items-center justify-center w-10 rounded-full aspect-square bg-[#dfe4ff] transition ease-in-out group-hover:bg-white group-hover:border-2 group-hover:border-redOrange">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={21}
          height={22}
          fill="#4262ff"
          viewBox="0 0 256 256"
        >
          <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z"></path>
        </svg>
      </span>
    ),
  },
  {
    id: 4,
    to: "./products",
    label: "Products",
    permission: ["Tenant User", "System User"],
    icon: (
      <span className="flex items-center justify-center w-10 rounded-full aspect-square bg-[#ffd6fa] transition ease-in-out group-hover:bg-white group-hover:border-2 group-hover:border-redOrange">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={21}
          height={22}
          fill="#ff2be5"
          viewBox="0 0 256 256"
        >
          <path d="M223.68,66.15,135.68,18a15.88,15.88,0,0,0-15.36,0l-88,48.17a16,16,0,0,0-8.32,14v95.64a16,16,0,0,0,8.32,14l88,48.17a15.88,15.88,0,0,0,15.36,0l88-48.17a16,16,0,0,0,8.32-14V80.18A16,16,0,0,0,223.68,66.15ZM128,32l80.34,44-29.77,16.3-80.35-44ZM128,120,47.66,76l33.9-18.56,80.34,44ZM40,90l80,43.78v85.79L40,175.82Zm176,85.78h0l-80,43.79V133.82l32-17.51V152a8,8,0,0,0,16,0V107.55L216,90v85.77Z"></path>
        </svg>
      </span>
    ),
    children: [
      {
        id: 1,
        to: "./products",
        label: "Products",
        permission: "GetAllProduct",
      },
      {
        id: 2,
        to: "./products/product-types",
        label: "Category ",
        permission: "GetAllProductType",
      },
      {
        id: 3,
        to: "./products/brands",
        label: "Brands",
        permission: "GetAllBrand",
      },
      {
        id: 4,
        to: "./products/tags",
        label: "Tags",
        permission: "GetAllTag",
      },
      {
        id: 5,
        to: "./products/seasons",
        label: "Seasons",
        permission: "GetAllSeason",
      },
      {
        id: 7,
        to: "./products/unit",
        label: "Unit",
        permission: "GetAllSeason",
      },
    ],
  },
  {
    id: 4,
    to: "./inventory-add-set",
    label: "Inventory",
    permission: ["Tenant User", "System User"],
    icon: (
      <span className="flex items-center justify-center w-10 rounded-full aspect-square bg-[#eaffbf] transition ease-in-out group-hover:bg-white group-hover:border-2 group-hover:border-redOrange">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={21}
          height={22}
          fill="#5f8f00"
          viewBox="0 0 256 256"
        >
          <path d="M80,40a40,40,0,1,0,40,40A40,40,0,0,0,80,40Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,80,104Zm96,16a40,40,0,1,0-40-40A40,40,0,0,0,176,120Zm0-64a24,24,0,1,1-24,24A24,24,0,0,1,176,56ZM80,136a40,40,0,1,0,40,40A40,40,0,0,0,80,136Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,80,200Zm136-24a8,8,0,0,1-8,8H184v24a8,8,0,0,1-16,0V184H144a8,8,0,0,1,0-16h24V144a8,8,0,0,1,16,0v24h24A8,8,0,0,1,216,176Z"></path>
        </svg>
      </span>
    ),
    children: [
      {
        id: 8,
        to: "./inventory-add-set",
        label: "My Inventory",
        permission: "GetInventoryLedger",
      },
      {
        id: 8,
        to: "./products/opening-balance",
        label: "Opening Balance",
        permission: "GetInventoryBalance",
      },
      {
        id: 2,
        to: "./purchase",
        label: "Purchase",
        permission: "GetAllPurchase",
      },
      {
        id: 1,
        to: "./inventory/inventor-transfer",
        label: "Inventory Transfer",
        permission: "GetAllInventoryTransfer",
      },
    ],
  },
  {
    id: 5,
    to: "./customer",
    label: "Cutomer",
    permission: ["Tenant User", "System User"],

    children: [
      {
        id: 1,
        to: "./customer",
        label: "Customer",
        permission: "GetAllCustomer",
      },
      {
        id: 2,
        to: "./customer/tag",
        label: "Tag",
        permission: "GetAllCustomerTag",
      },
      {
        id: 3,
        to: "./customer/tier",
        label: "Tier",
        permission: "GetAllCustomerTier",
      },
    ],
    icon: (
      <>
        <span className="flex items-center justify-center w-10 rounded-full aspect-square bg-[#ffd4c2] transition ease-in-out group-hover:bg-white group-[.menu-item-has-children.active]:bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21}
            height={22}
            fill="#8f2b00"
            viewBox="0 0 256 256"
          >
            <path d="M244.8,150.4a8,8,0,0,1-11.2-1.6A51.6,51.6,0,0,0,192,128a8,8,0,0,1-7.37-4.89,8,8,0,0,1,0-6.22A8,8,0,0,1,192,112a24,24,0,1,0-23.24-30,8,8,0,1,1-15.5-4A40,40,0,1,1,219,117.51a67.94,67.94,0,0,1,27.43,21.68A8,8,0,0,1,244.8,150.4ZM190.92,212a8,8,0,1,1-13.84,8,57,57,0,0,0-98.16,0,8,8,0,1,1-13.84-8,72.06,72.06,0,0,1,33.74-29.92,48,48,0,1,1,58.36,0A72.06,72.06,0,0,1,190.92,212ZM128,176a32,32,0,1,0-32-32A32,32,0,0,0,128,176ZM72,120a8,8,0,0,0-8-8A24,24,0,1,1,87.24,82a8,8,0,1,0,15.5-4A40,40,0,1,0,37,117.51,67.94,67.94,0,0,0,9.6,139.19a8,8,0,1,0,12.8,9.61A51.6,51.6,0,0,1,64,128,8,8,0,0,0,72,120Z"></path>
          </svg>
        </span>
        <span className="text-sm text-lightGrey transition ease-in-out group-hover:text-white group-[.menu-item-has-children.active]:text-white"></span>
      </>
    ),
  },
  {
    id: 5,
    to: "./user",
    label: "User",
    permission: ["Tenant User", "Poxfy User", "System User"],
    children: [
      {
        id: 1,
        to: "./user",
        label: "User",
        permission: "GetAllUserList",
      },
      {
        id: 2,
        to: "./user/role",
        label: "Role",
        permission: "GetRoleAllPermission",
      },
    ],
    icon: (
      <>
        <span className="flex items-center justify-center w-10 rounded-full aspect-square bg-[#def7ff] transition ease-in-out group-hover:bg-white group-[.menu-item-has-children.active]:bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21}
            height={22}
            fill="#00c3ff"
            viewBox="0 0 256 256"
          >
            <path d="M230.92,212c-15.23-26.33-38.7-45.21-66.09-54.16a72,72,0,1,0-73.66,0C63.78,166.78,40.31,185.66,25.08,212a8,8,0,1,0,13.85,8c18.84-32.56,52.14-52,89.07-52s70.23,19.44,89.07,52a8,8,0,1,0,13.85-8ZM72,96a56,56,0,1,1,56,56A56.06,56.06,0,0,1,72,96Z"></path>
          </svg>
        </span>
        <span className="text-sm text-lightGrey transition ease-in-out group-hover:text-white group-[.menu-item-has-children.active]:text-white"></span>
      </>
    ),
  },
  {
    id: 5,
    to: "./user/tenant",
    label: "Subscriptions",
    permission: ["Poxfy User", "System User"],
    children: [
      {
        id: 1,
        to: "./user/tenant",
        label: "Tenant",
        permission: "GetAllTenant",
      },
      {
        id: 2,
        to: "./inventory/packages",
        label: "Packages",
        permission: "GetAllPackage",
      },
    ],
    icon: (
      <>
        <span className="flex items-center justify-center w-10 rounded-full aspect-square bg-[#ffe3f2] transition ease-in-out group-hover:bg-white group-[.menu-item-has-children.active]:bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21}
            height={22}
            fill="#ff45a9"
            viewBox="0 0 256 256"
          >
            <path d="M196,88a27.86,27.86,0,0,0-13.35,3.39A28,28,0,0,0,144,74.7V44a28,28,0,0,0-56,0v80l-3.82-6.13A28,28,0,0,0,35.73,146l4.67,8.23C74.81,214.89,89.05,240,136,240a88.1,88.1,0,0,0,88-88V116A28,28,0,0,0,196,88Zm12,64a72.08,72.08,0,0,1-72,72c-37.63,0-47.84-18-81.68-77.68l-4.69-8.27,0-.05A12,12,0,0,1,54,121.61a11.88,11.88,0,0,1,6-1.6,12,12,0,0,1,10.41,6,1.76,1.76,0,0,0,.14.23l18.67,30A8,8,0,0,0,104,152V44a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V100a12,12,0,0,1,24,0v20a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0Z"></path>
          </svg>
        </span>
        <span className="text-sm text-lightGrey transition ease-in-out group-hover:text-white group-[.menu-item-has-children.active]:text-white"></span>
      </>
    ),
  },
  {
    id: 5,
    to: "./products/tax",
    label: "Setup",
    permission: ["Tenant User", "System User"],
    children: [
      {
        id: 1,
        to: "./outlet",
        label: "Outlet Management",
        permission: "GetAllOutletList",
      },
      {
        id: 2,
        to: "./store",
        label: "Store Management",
        permission: "GetUserStoreById",
      },
      {
        id: 3,
        to: "./loyality-point",
        label: "Loyality Point",
        permission: "GetLoyalityPoints",
      },
      {
        id: 4,
        to: "./cash-register-outlet",
        label: "Cash Register",
        permission: "GetAllCashRegisters",
      },
      {
        id: 1,
        to: "./products/tax",
        label: "Tax",
        permission: "GetAllTax",
      },
      {
        id: 1,
        to: "./user/my-account",
        label: "My Account",
        permission: "GetSubscription",
      },
    ],
    icon: (
      <>
        <span className="flex items-center justify-center w-10 rounded-full aspect-square bg-[#dbffdb] transition ease-in-out group-hover:bg-white group-[.menu-item-has-children.active]:bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21}
            height={22}
            fill="#00ff00"
            viewBox="0 0 256 256"
          >
            <path d="M226.76,69a8,8,0,0,0-12.84-2.88l-40.3,37.19-17.23-3.7-3.7-17.23,37.19-40.3A8,8,0,0,0,187,29.24,72,72,0,0,0,88,96,72.34,72.34,0,0,0,94,124.94L33.79,177c-.15.12-.29.26-.43.39a32,32,0,0,0,45.26,45.26c.13-.13.27-.28.39-.42L131.06,162A72,72,0,0,0,232,96,71.56,71.56,0,0,0,226.76,69ZM160,152a56.14,56.14,0,0,1-27.07-7,8,8,0,0,0-9.92,1.77L67.11,211.51a16,16,0,0,1-22.62-22.62L109.18,133a8,8,0,0,0,1.77-9.93,56,56,0,0,1,58.36-82.31l-31.2,33.81a8,8,0,0,0-1.94,7.1L141.83,108a8,8,0,0,0,6.14,6.14l26.35,5.66a8,8,0,0,0,7.1-1.94l33.81-31.2A56.06,56.06,0,0,1,160,152Z"></path>
          </svg>
        </span>
        <span className="text-sm text-lightGrey transition ease-in-out group-hover:text-white group-[.menu-item-has-children.active]:text-white"></span>
      </>
    ),
  },
  {
    id: 5,
    to: "./reporting/sales",
    label: "Reporting",
    permission: ["Tenant User", "System User"],
    children: [
      {
        id: 1,
        to: "./reporting/sales",
        label: "Sales",
        permission: "GetRoleAllPermission",
      },
      {
        id: 2,
        to: "./reporting/inventory",
        label: "Inventory",
        permission: "GetRoleAllPermission",
      },
      {
        id: 3,
        to: "./reporting/register",
        label: "Register",
        permission: "GetRoleAllPermission",
      },
      {
        id: 4,
        to: "./reporting/payment",
        label: "Payment",
        permission: "GetRoleAllPermission",
      },
      {
        id: 5,
        to: "./reporting/customer",
        label: "Customer",
        permission: "GetRoleAllPermission",
      },
      // {
      //   id: 6,
      //   to: "./reporting/custom-report",
      //   label: "Custom Report",
      // },
      // {
      //   id: 7,
      //   to: "./reporting/daily-report",
      //   label: "Daily Reports",
      // },
      // {
      //   id: 8,
      //   to: "./reporting/users-report",
      //   label: "Users",
      // },
    ],
    icon: (
      <>
        <span className="flex items-center justify-center w-10 rounded-full aspect-square bg-[#ffe3f2] transition ease-in-out group-hover:bg-white group-[.menu-item-has-children.active]:bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21}
            height={22}
            fill="#ff45a9"
            viewBox="0 0 256 256"
          >
            <path d="M196,88a27.86,27.86,0,0,0-13.35,3.39A28,28,0,0,0,144,74.7V44a28,28,0,0,0-56,0v80l-3.82-6.13A28,28,0,0,0,35.73,146l4.67,8.23C74.81,214.89,89.05,240,136,240a88.1,88.1,0,0,0,88-88V116A28,28,0,0,0,196,88Zm12,64a72.08,72.08,0,0,1-72,72c-37.63,0-47.84-18-81.68-77.68l-4.69-8.27,0-.05A12,12,0,0,1,54,121.61a11.88,11.88,0,0,1,6-1.6,12,12,0,0,1,10.41,6,1.76,1.76,0,0,0,.14.23l18.67,30A8,8,0,0,0,104,152V44a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V100a12,12,0,0,1,24,0v20a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0Z"></path>
          </svg>
        </span>
        <span className="text-sm text-lightGrey transition ease-in-out group-hover:text-white group-[.menu-item-has-children.active]:text-white"></span>
      </>
    ),
  },
];
