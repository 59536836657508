import React from "react";
import { Select } from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";

const CustomSelect = ({
  defaultValue,
  loading = false,
  options,
  mode,
  value,
  disabled,
  dataTestId,
  placeholder,
  maxTagCount,
  onChange,
  onDeselect,
  showSearch,
  filterOption = (input, option) => {
    return option?.label?.toLowerCase().includes(input.toLowerCase()) ?? true;
  },
  onSearch,
  allowClear = true,
  ...rest
}) => {
  return (
    <div>
      <Select
        mode={mode}
        className="min-h-[40px]"
        showSearch={showSearch}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        // className="block leading-none py-3 pr-10 pl-16 border w-full rounded-full w-100"
        // className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-6 outline-0"
        loading={loading}
        options={options}
        placeholder={placeholder}
        value={value}
        filterOption={filterOption}
        optionFilterProp="children"
        disabled={disabled}
        maxTagCount={maxTagCount}
        suffixIcon={
          showSearch ? (
            <SearchOutlined className={"icon-pointer-event"} />
          ) : (
            <DownOutlined className={"custom-select-icon icon-pointer-event"} />
          )
        }
        data-testid={dataTestId}
        onChange={onChange}
        onSearch={onSearch}
        notFoundContent={loading ? "Loading..." : null}
        defaultValue={defaultValue}
        allowClear={allowClear}
        onDeselect={onDeselect}
        {...rest}
      />
    </div>
  );
};

export default CustomSelect;
