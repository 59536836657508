import { Col, Row, Select } from "antd";
import CustomTableForReporting from "../../../../pages/reporting/table";
import { useEffect, useState } from "react";
import moment from "moment";
import CustomDateRange from "../../../shared/customDateRange";

function SalesInstruction() {
  const [filterValue, setFilterValue] = useState({
    startDate: "", // for the start date
    endDate: "",
    paymentTypes: "",
    paymentStatuses: "",
  });
  const [dates, setDates] = useState(null);

  useEffect(() => {
    setFilterValue((prevState) => ({
      ...prevState,
    }));
  }, []);
  const columnsSalesSummary = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (_, row) => <p>{new Date(row.orderDate).toDateString()}</p>,
    },
    {
      title: "Customer",
      render: (_, row) => {
        return row?.customer?.fullName || row?.customerName;
      },
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: "Payment Type",
      render: (_, row) => {
        const a = ["Cash", "Card", "Loyality"];
        if (row.receivedCash <= 0) {
          a.splice(a.indexOf("Cash"), 1);
        }
        if (row.receivedCredit <= 0) {
          a.splice(a.indexOf("Card"), 1);
        }
        if (row.receivedLoyality <= 0) {
          a.splice(a.indexOf("Loyality"), 1);
        }

        return a.join(", ");
      },
    },
    {
      title: "Sale Type",
      render: (_, row) => {
        return row?.salesType?.toUpperCase() || "SALES";
      },
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (tax) => {
        return Number(tax).toFixed(2);
      },
    },
    {
      title: "Total Sale",
      dataIndex: "totalSale",
      key: "totalSale",
      render: (totalSale) => {
        return Number(totalSale).toFixed(2);
      },
    },
  ];
  const handleChange = (value) => {
    setDates(value);
    if (value) {
      const startDate = value[0].toString();
      const endDate = value[1].toString();
      setFilterValue((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
      }));
    }
  };
  return (
    <>
      <Row>
        <Col span={8}>
          <CustomDateRange
            value={dates}
            handleChange={handleChange}
            format="YYYY-MM-DD"
          />
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Select
            style={{ width: "100%" }}
            placeholder="Payment Type"
            options={[
              {
                label: "CASH",
                value: "CASH",
              },
              {
                label: "CARD",
                value: "CARD",
              },
              {
                label: "CREDIT",
                value: "CREDIT",
              },
              {
                label: "LOYALITY",
                value: "LOYALITY",
              },
            ]}
            onChange={(e) =>
              setFilterValue({ ...filterValue, paymentTypes: e })
            }
          />
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Select
            placeholder="Payment Status"
            style={{ width: "100%" }}
            options={[
              {
                label: "PARKED",
                value: "PARKED",
              },
              {
                label: "PAID",
                value: "PAID",
              },
              {
                label: "UNPAID",
                value: "UNPAID",
              },
            ]}
            onChange={(e) =>
              setFilterValue({ ...filterValue, paymentStatuses: e })
            }
          />
        </Col>
      </Row>
      <div className="flex flex-col justify-between mt-4">
        <CustomTableForReporting
          columns={columnsSalesSummary}
          endPoint={`sales`}
          filters={filterValue}
          showActions={false}
        />
      </div>
    </>
  );
}
export default SalesInstruction;
