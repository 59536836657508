import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const PublicNotFound = () => {
  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        // extra={<Button type="primary">Back Home</Button>}
      />
    </div>
  );
};

export default PublicNotFound;
